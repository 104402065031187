import React, {useState, useEffect}  from 'react'
import {useDispatch} from "react-redux";
import classes from "../Card/Card.module.scss";
import {createMarkup, deepGet, numberForHuman, redirect, roundPrice, toNum} from "../../library/functions";
import {dotenv, routes} from "../../config/config";
import {Button, Col, Form, Input, InputNumber, Row, Slider, Tooltip, Typography} from "antd";
import {NavigateBtn} from "../Navigate/Navigate";
import {useTranslation} from "react-i18next";
import {EditOutlined, PlusOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import {SimpleModal} from "../Modal/SimpleModal";
import {FormItemWrapper} from "../Form/FormItemWrapper";
import {LabelSwitch} from "../Form/Field/LabelSwitch";
import {reachGoal} from "../../library/metrics";
import {NoForm} from "../Form/NoForm";
import {FormItemLabel} from "../Form/FormItemLabel";
import {notice} from "../../library/notice";

const {Text} = Typography;

const CardAccount = ({admin, order, discount, bio, balance, coins, status, changeStatus}) => {
    const section = 'tariff'

    // init hooks
    const {t} = useTranslation();
    const dispatch = useDispatch()

    // local locale func
    const l = (c) => t('project.tariff.' + c);
    const item = admin.user

    const discountItem = discount.list.length ? discount.list[0] : {'id': 0, 'amount': 10, 'name': ''}
    const admin_phones = (item && item.contacts) ? item.contacts.filter(e => e.type === 'phone') : []
    const admin_phone = admin_phones.length ? admin_phones[0].value : ''

    const [orderForm] = Form.useForm();
    const [pageParams, setPageParams] = useState({
        amount: {
            months: 1,
            order: 0,
            project: 0,
            discount: 0,
        },
        order: {
            phone: '',
            subscribe: false,
        },
        modal: {
            tariff: false,
            order: false,
            promo: false,
            project: false,
            ai: false,
        },
        promo: {
            show: false,
            bonus: 10,
            code: '',
            name: '',
            value: 0,
        },
    })

    useEffect(() => {
        if (order.url) redirect(order.url)
        // if (order.url) console.log('order.url', order.url)
        // else if (order.url && !order.loading.balance) dispatch(setOrderUrl(''))
        //eslint-disable-next-line
    }, [order.url])

    useEffect(() => {
        if (discountItem.id) {
            // console.log('discountItem.id', discountItem.id)
            // setState('promo.name', discountItem.name)
            // setState('promo.bonus', discountItem.amount)

            setPageParams({
                ...pageParams,
                promo: {
                    ...pageParams.promo,
                    name: discountItem.is_on ? discountItem.name : '',
                    bonus: discountItem.amount,
                }
            })
        }
        //eslint-disable-next-line
    }, [discountItem.id])

    const setState = (key, value) => {
        // console.log('setState', key, value)

        const keys = key.split('.');
        const firstKey = keys.shift();
        const lastKey = keys.pop();

        let newSection = {...pageParams[firstKey]};
        newSection[lastKey] = value;
        setPageParams({...pageParams, [firstKey]: newSection});
    }

    const showOrderModal = () => {
        const currentValues = orderForm.getFieldsValue();

        const newValues = {
            amount: currentValues.amount > 0 ? currentValues.amount : 1000,
            phone: currentValues.phone ?? admin_phone,
        }

        orderForm.setFieldsValue(newValues);
        setPageParams({
            ...pageParams,
            amount: {...pageParams.amount, order: newValues.amount},
            modal: {...pageParams.modal, order: true},
        })
    }

    const onOrderFormChange = (changedValues) => {
        if (changedValues.amount !== undefined) setState('amount.order', toNum(changedValues.amount));
    }

    const onOrderFormSubmit = () => {
        // console.log('onOrderFormSubmit', value)

        // catch metrics goal
        reachGoal('order', {order_price: pageParams.amount.order})

        const orderValues = orderForm.getFieldsValue();
        // console.log('orderValues', orderValues)
        const data = {
            // title: l('account.amount.product_title'),
            price: orderValues.amount,
            promo: orderValues.promo,
            user_phone: orderValues.phone,
            subscribe: pageParams.order.subscribe,
            related_project_id: 0,
        }
        // console.log('onOrderFormSubmit data', data)
        dispatch({type: 'createAppOrder', admin, data})
    }

    const onOk = () => {
        orderForm.submit()
    }

    const onPromoSave = () => {
        // console.log('promoSave', pageParams.promo.name, pageParams.promo.bonus)
        if (!admin.user.id) {
            notice.error('Admin not authorized')
            return
        }

        const discountData = {
            project_id: dotenv.main_project,
            product_id: dotenv.main_product,
            title: l('account.discount.item.title') + ': ' + pageParams.promo.name,
            // name: pageParams.promo.name,
            amount: pageParams.promo.bonus,
            partner_user_id: admin.user.id,
            mode: 'percent',
            params: {
                bonus: {
                    partner: {
                        currency: 'coins',
                        mode: 'percent',
                        amount: Math.round((20 - pageParams.promo.bonus) * 10, 2),
                        limit: 0,
                    }
                }
            }
        }

        if (discountItem.id) {
            if (pageParams.promo.name) {
                discountData['is_on'] = true
                discountData['name'] = pageParams.promo.name
            } else discountData['is_on'] = false

            dispatch({
                type: 'updateDiscount', admin, data: {
                    id: discountItem.id,
                    ...discountData
                }
            })
        } else {
            if (!pageParams.promo.name) {
                notice.info(l('account.promo.error.name_is_empty'))
                return
            }
            discountData['name'] = pageParams.promo.name
            dispatch({type: 'createDiscount', admin, data: discountData})
        }
    }

    return (
        <div className="account-block margin-bottom-ps margin-top-ps site-layout-background border-radius-lp">
            <div className={classes.card}>
                <Row gutter={1}>
                    <Col xs={24}>
                        {/* DESKTOP only */}
                        <div className="hidden-xs">
                            <div className={`${classes.image} float-left`}>
                                <img src={item.photo_url} alt={'Avatar'}/>
                            </div>
                            <div className={`float-right`}>
                                <NavigateBtn
                                    link={routes.admin.settings}
                                    icon={<EditOutlined/>}
                                    className={classes.btn_edit}
                                >{t('common.action.edit')}</NavigateBtn>
                            </div>
                            <div className={classes.offer}>
                                <h2>{item.first_name} {item.last_name}</h2>
                                <p className={classes.offer_text}>
                                    <span dangerouslySetInnerHTML={createMarkup(bio)}/>
                                </p>
                                {/*<p>
                                    {dotenv.is_gamificated ? <Tooltip title={t('common.status.change')} placement={'right'}>
                                        <Button
                                            onClick={changeStatus}
                                            icon={status ? <AiOutlineCheck/> : <AiOutlineClockCircle/>}
                                            className={` ${classes.status} btn-colored ${status ? "btn-success" : "btn-gray"}`}
                                        >
                                            {status ? t('account.status.free') : t('account.status.working')}
                                        </Button>
                                    </Tooltip> : null}
                                </p>*/}
                            </div>
                        </div>

                        {/* MOBILE only */}
                        <div className="visible-xs">
                            <div className={classes.row_mob}>

                                <div className={`${classes.image} float-left`}>
                                    <img alt="Avatar" src={item.photo_url}/>
                                </div>

                                <div className={`float-right`}>
                                    <NavigateBtn
                                        link={routes.admin.settings}
                                        icon={<EditOutlined/>}
                                        className={classes.btn_edit}
                                    />
                                </div>

                                <div className={classes.offer}>
                                    <h3>{item.first_name} {item.last_name}</h3>
                                </div>

                            </div>
                            <div>
                                <div className={classes.offer_text}>
                                    <span dangerouslySetInnerHTML={createMarkup(bio)}/>
                                </div>
                            </div>
                        </div>

                        <Row gutter={1} className="margin-top-sm">
                            <Col xs={24} sm={12}>
                                <h2>{t('project.tariff.account.balance.short_title')}</h2>

                                <div>
                                    <h3 className="inline margin-right-sm">
                                        {numberForHuman(roundPrice(balance))} {t('common.money.abbreviation')}
                                    </h3>

                                    <Button
                                        // className="btn-default-gray"
                                        size="small"
                                        type="primary"
                                        loading={order.loading.balance}
                                        children={t('common.action.add_funds')}
                                        icon={<PlusOutlined/>}
                                        onClick={showOrderModal}
                                    />
                                </div>
                            </Col>

                            <Col xs={24} sm={12}>
                                <h2>{t('project.tariff.account.promo.title')}</h2>

                                <div>
                                    <h3 className="inline margin-right-sm">
                                        {numberForHuman(coins / 10)} {t('common.money.abbreviation')}
                                    </h3>

                                    <Button
                                        className="btn-default-gray"
                                        size="small"
                                        // type="primary"
                                        loading={order.loading.balance}
                                        children={t('common.action.get')}
                                        icon={<QuestionCircleOutlined/>}
                                        onClick={() => setState('modal.promo', true)}
                                    />
                                </div>
                            </Col>
                        </Row>

                    </Col>
                </Row>

                {/*<div className="hidden-xs">
                    <NavigateLink
                        link={routes.admin.settings}
                        children={t('common.action.change')}
                        className="float-right"
                    />
                    <span>cash.multy.expert</span>
                </div>*/}


                <div id="modals">
                    {/* Add funds to account balance */}
                    <SimpleModal
                        isVisible={pageParams.modal.order}
                        setVisible={(value) => {
                            setState('modal.order', value)
                        }}
                        onOk={onOk}
                        title={l('account.balance.modal.title')}
                        loadingOnOk={order.loading.balance}
                        disabled={pageParams.amount.order === 0} // pageParams.amount.order === 0 && tariffPrice === 0
                    >
                        <Form
                            id="order-form"
                            name="order-form"
                            layout="vertical"
                            form={orderForm}
                            onValuesChange={onOrderFormChange}
                            onFinish={onOrderFormSubmit}
                        >
                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                <Form.Item
                                    name="amount"
                                    label={l('account.balance.modal.amount.label')}
                                >
                                    <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="always-show-arrows"
                                        min={0}
                                        step={1000}
                                        placeholder="1000"
                                        // onChange={(value) => setState('amount.order', value)}
                                        // defaultValue={tariffPrice ? tariffPrice : null}
                                    />
                                </Form.Item>
                            </FormItemWrapper>

                            <FormItemWrapper className="" desc={l('account.balance.modal.promo.desc')}>
                                <Form.Item name="promo" label={l('account.balance.modal.promo.label')}>
                                    <Input
                                        showCount={true}
                                        maxLength={30}
                                        placeholder={t('common.placeholder.text')}
                                        autoComplete='off'
                                        // onChange={(event) => {
                                        //     // console.log('balance.modal.promo', event.target.value)
                                        //     setState('promo.code', event.target.value)
                                        // }}
                                        defaultValue={pageParams.promo.code}
                                    />
                                </Form.Item>
                            </FormItemWrapper>

                            <FormItemWrapper desc={l('account.balance.modal.phone.desc')}>
                                <Form.Item name="phone" label={l('account.balance.modal.phone.label')}>
                                    <Input
                                        inputMode="numeric"
                                        pattern="\+*[0-9]*"
                                        placeholder={t('common.placeholder.text')}
                                        // placeholder="+7 987 654 32 10"
                                        // onChange={(event, val) => {
                                        //     console.log('value', event, val)
                                        //     setState('order.phone', event.target.value)
                                        // }}
                                        defaultValue={admin_phone}
                                    />
                                </Form.Item>
                            </FormItemWrapper>

                            <FormItemWrapper desc={l('account.balance.modal.subscribe.desc')}>
                                <Form.Item name="subscribe">
                                    <LabelSwitch
                                        label={l('account.balance.modal.subscribe.label')}
                                        onChange={(value) => setState('order.subscribe', value)}
                                        checked={pageParams.order.subscribe}
                                    />
                                </Form.Item>
                            </FormItemWrapper>
                        </Form>
                    </SimpleModal>

                    {/* Get Coins */}
                    <SimpleModal
                        isVisible={pageParams.modal.promo}
                        setVisible={(value) => {
                            setState('modal.promo', value)
                        }}
                        onOk={onPromoSave}
                        actionLabel="save"
                        title={l('account.promo.modal.title')}
                    >
                        <div dangerouslySetInnerHTML={{__html: l('account.promo.modal.desc')}}/>
                        <NoForm className="margin-top-sm">
                            <FormItemWrapper className="" desc={l('account.promo.modal.field.desc')}>
                                <FormItemLabel label={l('account.promo.modal.field.label')}/>
                                <Input
                                    showCount={true}
                                    maxLength={30}
                                    placeholder={t('common.placeholder.text')}
                                    autoComplete='off'
                                    defaultValue={pageParams.promo.name}
                                    onBlur={(e) => {
                                        setState('promo.name', e.target.value)
                                    }}
                                    onChange={(value) => {
                                        setState('promo.name', value)
                                    }}
                                />
                            </FormItemWrapper>
                            <div className="float-right">{pageParams.promo.bonus}%</div>
                            <Text>{l('account.promo.modal.slider.label')}</Text>
                            <Slider
                                className="primary"
                                min={0}
                                max={20}
                                step={1}
                                onChange={(value) => setState('promo.bonus', value)}
                                value={pageParams.promo.bonus}
                                defaultValue={pageParams.promo.bonus}
                            />
                            <div className="margin-top-sm">
                                <div dangerouslySetInnerHTML={
                                    {
                                        __html: t('project.' + section + '.account.promo.modal.slider.desc', {
                                            postProcess: 'sprintf',
                                            sprintf: [
                                                numberForHuman((20 - pageParams.promo.bonus) / 100 * 1000 * 10)
                                            ]
                                        })
                                    }
                                }/>
                            </div>
                        </NoForm>
                    </SimpleModal>

                </div>
            </div>
        </div>
    )
}

export default CardAccount

export const ServiceDescription = ({about}) => {
    const {t} = useTranslation();

    return (
        <div className={classes.content}>
            <div dangerouslySetInnerHTML={createMarkup(about)}/>
            {/*<p className="margin-top-sm">*/}
            {/*    <NavigateLink*/}
            {/*        link={routes.admin.settings}*/}
            {/*        children={t('common.action.change')}*/}
            {/*    />*/}
            {/*</p>*/}

        </div>
    )
}



