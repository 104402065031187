import React from 'react'
import classes from "./ContentCard.module.scss";

export const ContentCard = (
    {
        children,
        className = '',
        flat_xs= false,
        interactive = false,
        active = false,
        onClick = null
    }
) => {
    let classNames = `site-layout-background ${classes.card}`;
    if (className) classNames += ' ' + className
    if (flat_xs) classNames += ' ' + classes.flat_on_xs

    if (interactive) classNames += ' interactive'
    if (active) classNames += ' active'

    return (
        <div className={classNames} onClick={onClick}>
            {children}
        </div>
    )
}


