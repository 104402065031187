import React, {useEffect, useState, useRef} from 'react'
import {Alert, Button, Col, Form, Layout, PageHeader, Row, Tabs, Typography} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import cryptoRandomString from "crypto-random-string";
import {
    createMarkup,
    createObjectFromObjectsArray,
    isMainProject,
    objDeepExtend,
    ucFirst,
    deepGet,
    toNum,
    inArray,
    downloadFileFromText,
} from "../../../library/functions";
import {dotenv, routes} from "../../../config/config";
import AppWrapper from "../../Layouts/AppWrapper/AppWrapper";
import {formCreateOrUpdate, handleContainers} from "../../../library/containers";
import {useTranslation} from "react-i18next";
import {useHotkeys} from "react-hotkeys-hook";
import {notice} from "../../../library/notice";
import {
    ArrowLeftOutlined,
    BranchesOutlined,
    CheckOutlined,
    DownloadOutlined,
    DownOutlined,
    FireOutlined,
    FormOutlined,
    LaptopOutlined,
    LockOutlined,
    LoginOutlined,
    MailOutlined,
    SettingOutlined,
    UnlockOutlined,
    UpOutlined,
    UsbOutlined
} from "@ant-design/icons";
import {Collapse} from "react-collapse/lib/Collapse";
import {layout_graph} from "../../../schemas/backend/layout_graph";
import {composeGraphItem, GraphBlock} from "../../Flow/Block";
import {FormFields} from "../../../components/Form/Field/FormFields";
import {FormSubmit} from "../../../components/Form/FormSubmit";
import FieldsMaker from "../../../components/Form/Field/FieldsMaker";
import {LayoutMaker} from "./LayoutMaker";
import {setLayoutItem} from "../../../redux/reducers/LayoutReducer";
import {setLayoutRows} from "../../../redux/reducers/BlockReducer";

const {Title} = Typography;
const {Content, Header} = Layout;

let layoutLocalIds = {
    pre: 4,
    cond: 8,
    post: 12,
    layout: -1,
}

const LayoutEdit = () => {
    const {t} = useTranslation()
    const section = 'layout'
    const Section = ucFirst(section);
    const adminSection = section;
    const categorySection = 'site'

    // data from URL params
    const params = useParams()
    const id = Number(params.id)
    const project_id = Number(params.project_id)

    // data from GET params
    const [searchParams] = useSearchParams();
    const data_site_id = toNum(searchParams.get('data_site_id'))
    const back_to_page = toNum(searchParams.get('back_to_page'))
    let folder_id = toNum(searchParams.get('folder')) // for fast create in folder
    let site_id = searchParams.get('site_id')

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {admin, project, layout, site, theme, page, pb, folder} = useSelector(store => store)
    const item = layout.item;
    const categoryList = site.list || []
    const layoutRows = pb.layout;

    const project_item = project.item;
    const isAllowed = project_item.sections && inArray(section, project_item.sections);

    const categories = createObjectFromObjectsArray(categoryList);
    const pages = createObjectFromObjectsArray(page.short.list);
    const themes = {
        '': t('layout.form.params.theme.name.values.off'),
        ...createObjectFromObjectsArray(theme.short.list, 'name')
    }

    const [tab, setTab] = useState(id ? "layout" : "settings")
    const [opened, setOpened] = useState({
        pre: true, post: true, cond: true, auth: true, access: true, events: true
    })

    // itis form states
    const [avatarUrl, setAvatarUrl] = useState('')
    const [folderId, setFolderId] = useState(0)
    const [byVars, setByVars] = useState(false)
    const [formValues, setFormValues] = useState({
        title: '',
        css: '',
        name: cryptoRandomString({length: 10}),
        type: 'page',
        icon_name: 'laptop-code',
        site_id: site_id || null,
        is_on: true,
        params: {
            folder_id: folder_id,
            theme: {
                name: null,
            },
            style: {
                light: false,
            }
        }
    })

    // get form and set values
    const [form] = Form.useForm();
    const [styleForm] = Form.useForm();
    const [fieldsForm] = Form.useForm();
    const [authForm] = Form.useForm();
    const [eventsForm] = Form.useForm();

    const addStyles = (value) => {
        // add new styles to form.css value end:
        let oldValue = formValues.css;
        if (oldValue) oldValue += '\n\n';
        let newValue = value;
        // remove ```scss or ``` or any non CSS data:
        newValue = newValue.replace(/```(scss|css)?/g, '');
        setFormValues({...formValues, css: oldValue + newValue})
        styleForm.setFieldsValue({css: oldValue + newValue})
    }

    const isMainProj = isMainProject(project_item.id, project);
    const formFields = [
        {
            placeholder: t('common.placeholder.text'),
            name: "title",
            type: "text",
            required: true,
        },
        {
            name: "name",
            type: "text",
            label: t('common.form.name.label'),
            desc: t('common.form.name.desc'),
            placeholder: t('common.form.name.placeholder'),
            required: true,
        },
        {
            name: "type",
            type: "menu",
            placeholder: '',
            values: [
                'page',
                'category',
                'section',
                'landing',
                'mini',
                'form',
                'system',
            ],
            localized: true,
        },
        {
            name: "site_id",
            type: "menu",
            data: 'site',
            values: categories,
        },
        {
            name: "params.folder_id",
            type: "folder",
            folder_list: folder.list,
            desc: t('common.form.folder.desc'),
        },
        {
            name: "is_on",
            type: "switcher",
            label: t('common.form.is_on.label'),
        },
        {
            name: "params.ignore_vars",
            type: "switcher",
        },
        // {
        //     name: "params.privacy.hide_desc",
        //     type: "switcher",
        // },

        {
            name: "heading.theme",
            type: "heading",
        },
        {
            name: "params.theme.name",
            type: "menu",
            data: 'theme',
            values: themes,
        },
        {
            name: "params.style.light",
            type: "switcher",
        },
        {
            name: "heading.list",
            type: "heading",
        },
        {
            name: "params.hide_tabs",
            placeholder: t('common.status.neuter.unset'),
            type: "tags",
            values: [
                'seo',
                'style',
            ],
            localized: true,
        },
        {
            name: "params.screens",
            placeholder: t('common.status.neuter.unset'),
            type: "tags",
            values: [
                'ts',
                'xs',
                'sm',
                'md',
                'lg',
                'xl',
            ],
            localized: true,
        },
        {
            name: "params.original_image",
            type: "switcher",
        },
        {
            name: "icon_name",
            type: "icon",
            placeholder: '',
            // section: 'common',
        },
        {
            name: "photo_url",
            type: "avatar",
            form: form,
            imageType: 'card',
            section: section,
            admin: admin,
            project_id: project_item.id,
            photo_url: avatarUrl,
            placeholder: t('common.form.photo_url.placeholder'),
            isVisible: isMainProj,
            aspect: 1.2
        },
    ]
    const styleFields = [
        {
            name: "styles",
            type: "heading",
        },
        {
            name: "css",
            type: "code",
            form: styleForm,
            language: 'scss',
            placeholder: 'body {font-size: 16px;}',
            section: section,
        },
        {
            name: "generate_css",
            type: "ai",
            section: 'page',
            form: form,
            admin: admin,
            project_id: project_item.id,
            formValues: formValues,
            prompt: 'You are React page form code assistant. Answer ONLY with SCSS code to add it to form field value! Any necessary explanation write as a comments! If I asking about something else - just skip it! Newer add any other text which is not SCSS code.',
            onFinish: addStyles,
        },
    ]
    const authFields = [
        {
            name: "auth.require",
            type: "switcher",
        },
        // {
        //     name: "app.tg",
        //     type: "hidden",
        // },
        // {
        //     name: "app.vk",
        //     type: "hidden",
        // },
        byVars ? {
            name: "page.unauthorized",
            type: "text",
            placeholder: t('common.placeholder.default'),
        } : {
            name: "page.unauthorized",
            type: "menu",
            data: 'page',
            values: pages,
            placeholder: t('common.placeholder.default'),
        },
        {
            name: 'page.set_by_vars',
            type: 'switcher',
            onChange: (value) => {
                setByVars(value)
            },
        },
    ];
    const accessFields = [
        {
            name: "access.inherit",
            type: "switcher",
        },
        {
            name: "access.current",
            type: "switcher",
        },
        byVars ? {
            name: "page.forbidden",
            type: "text",
            placeholder: t('common.placeholder.default'),
        } : {
            name: "page.forbidden",
            type: "menu",
            data: 'page',
            values: pages,
            placeholder: t('common.placeholder.default'),
        },
    ];
    const eventsFields = [
        {
            name: "events.trigger",
            type: "tags",
            placeholder: t('common.placeholder.tags'),
            values: [
                '0',
                '5',
                '10',
                '15',
                '30',
                '60',
                '300',
                '3600',
            ],
            localized: false,
        },
    ];

    // get data from API first
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized && project_item.id && id && (!item.id || id !== item.id)) {
            dispatch({type: 'get' + ucFirst(section) + 'Item', admin, id});
        }

        if (admin.authorized && project_item.id && !categoryList.length) {
            dispatch({type: 'get' + ucFirst(categorySection) + 'List', admin, filters: {project_id: project_item.id}});
        }

        // if (admin.authorized && project_item.id && page.short.list.length <= 0) {
        //     let pagesFilters = {project_id: project_item.id}
        //     if (site_id) pagesFilters.site_id = site_id;
        //     else if (item.site_id) pagesFilters.site_id = item.site_id;
        //     dispatch({type: 'getPageShortList', admin, filters: pagesFilters});
        // }

        if (admin.authorized && project_item.id && theme.short.list.length <= 0) {
            let site_ids = [0]
            if (site_id) site_ids.push(site_id)
            dispatch({type: 'getThemeShortList', admin, filters: {project_id: project_item.id, site_ids}});
        }
    }, [admin.authorized && project_item.id, id])

    useEffect(() => {
        if (admin.authorized && project_item.id && page.short.list.length <= 0) {
            let pagesFilters = {project_id: project_item.id}
            if (data_site_id) pagesFilters.site_id = data_site_id;
            else if (site_id) pagesFilters.site_id = site_id;
            else if (item.site_id) pagesFilters.site_id = item.site_id;
            dispatch({type: 'getPageShortList', admin, filters: pagesFilters});
        }
        // else {
        //     console.info('getPageShortList', admin.authorized && project_item.id && page.short.list <= 0, page.short.list)
        // }
    }, [admin.authorized && project_item.id, page.short.list.length])

    // set values to FORM if correct data received
    useEffect(() => {
        if (item.id === id) {
            let values = {...item}
            const valuesParams = values.params || {}
            if (!item.parent_id) values.parent_id = null

            if (values.params === null) values.params = {}
            values.site_id = values.site_id ? String(values.site_id) : null;

            form.setFieldsValue(values);
            styleForm.setFieldsValue({css: values.css});

            authForm.setFieldsValue({
                auth: deepGet(valuesParams, ['auth'], {}),
                access: deepGet(valuesParams, ['access'], {}),
                page: deepGet(valuesParams, ['page'], {}),
            });

            setByVars(deepGet(valuesParams, ['page', 'set_by_vars'], false))

            eventsForm.setFieldsValue({
                events: deepGet(valuesParams, ['events'], {}),
            });

            setAvatarUrl(item.photo_url) // for upload field
            if (values.folder_id !== undefined) setFolderId(deepGet(valuesParams, ['folder_id'], 0))
            setFormValues(values)

            dispatch(setLayoutRows(values.layout || []))
            layoutLocalIds.layout = deepGet(valuesParams, ['nextBlockId'], -1)
        } else if (item.id && item.id !== id) {
            console.info('Reset layout item')
            dispatch(setLayoutItem({}));
        }

        return () => {
            // reset Local IDs to defaults
            layoutLocalIds = {
                pre: 4,
                cond: 8,
                post: 12,
                layout: -1,
            }

            // and reset layout rows too
            dispatch(setLayoutRows([]))
        }
        //eslint-disable-next-line
    }, [item])

    useHotkeys(['ctrl+s', 'command+s'].join(','), e => {
        e.preventDefault();
        // console.log('useHotkeys', e.code);

        if (!id) return;

        // run key code actions
        switch (e.code) {
            case 'KeyS':
                onFinish(false);
                break;

            default:
                break;
        }
    }, [item, form, styleForm, authForm, eventsForm, fieldsForm, layoutRows]);

    // compose form functions
    const backToList = (folder_id = false) => {
        let url;

        if (back_to_page) {
            url = `${routes.project_list}/${project_id}/${categorySection}${routes.local.page_list}/${back_to_page}`
            if (data_site_id) url += `?site_id=${data_site_id}`
        }
        else {
            if (folder_id === false || typeof folder_id !== 'number') folder_id = folderId;
            url = `${routes.project_list}/${project_id}/${adminSection}?folder=${folder_id}`
            if (site_id) url += `&site_id=${site_id}`
        }

        navigate(url);
    }

    const handleLayoutRows = (inputRows) => {
        let rows = [];

        for (const rowData of inputRows) {
            let row = {...rowData};
            row.items = [];

            for (const colData of rowData.items) {
                let col = {...colData};
                col.items = [];

                for (const sectData of colData.items) {
                    col.items.push({
                        ...sectData,
                        items: handleContainers(sectData.items),
                    });
                }

                row.items.push(col);
            }

            rows.push(row);
        }

        return rows;
    }

    const composeItem = () => {
        let values = form.getFieldsValue();
        let stylesValues = styleForm.getFieldsValue();

        if (!values.title || !values.name) {
            notice.error(t('error.validation.fill_required'));

            for (const key of ['title', 'name']) {
                if (!values[key]) form.setFields([{name: key, errors: [t('error.validation.required')]}]);
            }

            if (tab !== 'settings') setTab('settings');
            return false;
        }

        // logic components
        const graphItem = getGraph();
        values['logic'] = graphItem.logic;

        // fields maker form
        values['form'] = Object.values(fieldsForm.getFieldsValue());
        values['css'] = stylesValues.css;

        if (layoutRows) values['layout'] = handleLayoutRows(layoutRows);

        if (!values.params) values.params = {};
        values.params['nextBlockId'] = layoutLocalIds.layout;

        objDeepExtend(values.params, authForm.getFieldsValue());
        objDeepExtend(values.params, eventsForm.getFieldsValue());

        values.site_id = toNum(values.site_id);

        return values;
    }

    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (back = true) => {
        const values = composeItem();
        if (!values) return;

        formCreateOrUpdate(Section, values, null, id, project_item, admin, dispatch, true, !back)
        if (back) backToList(parseInt(values.params.folder_id))
    }

    const downloadItem = () => {
        const values = composeItem();
        if (!values) return;

        const exportFields = [
            // 'id',
            // 'project_id',
            // 'site_id',
            'title',
            'name',
            'type',
            'form',
            'is_on',
            'layout',
            'logic',
            'icon_name',
            'photo_url',
            'params',
            'css',
        ]

        let itemData = {};
        for (const field of exportFields) {
            itemData[field] = values[field];
        }

        const itemDataJson = JSON.stringify(itemData);
        const fileName = dotenv.app_name + '-' + section + '-' + item.id + '-' + Date.now();
        downloadFileFromText(itemDataJson, fileName, 'json');
    }

    // toggle collapsible sections
    const toggleCollapsed = (key) => setOpened({...opened, [key]: !opened[key]})

    let graphItem = {};
    // TODO: to external func
    if (!id || !item.logic) graphItem = composeGraphItem(project_item.id, layout_graph, 'New ' + section);
    else if (item && item.logic) graphItem = {
        id: 'layout-' + item.id,
        project_id: project_item.id,
        logic: item.logic,
    }

    // TODO: to external func
    const RenderSectionTitle = (name, icon, count = null) => {
        const suffix = item.status === 'sent' ? 'title_sent' : 'title'
        return <Title
            level={3}
            className="collapsible-subheader user-select-none margin-top-pm"
            onClick={() => toggleCollapsed(name)}
        >
            {icon} {t(adminSection + '.section.' + name + '.' + suffix)}{count == null ? null : ': ' + count} <span
            className="float-right">
                {opened[name] ? <UpOutlined/> : <DownOutlined/>}
            </span>
        </Title>
    }

    const getNewBlockId = () => {
        const resultId = layoutLocalIds.layout;
        layoutLocalIds.layout = resultId - 1;
        return resultId;
    }

    const graphRef = useRef(null);
    const [nodesState, setNodesState] = useState([]);

    const getGraph = () => {
        if (graphRef.current) return graphRef.current.composeGraphDataForSave(); else return {};
    };

    const renderFormFields = () => {
        return (<Form
            form={fieldsForm}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_fields'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FieldsMaker
                t={t}
                id={id}
                item={item}
                propName="form"
                section={section}
                form={fieldsForm}
            />
        </Form>);
    }

    const renderSettings = () => {
        if (!formFields.length) return null;

        return (<Form
            form={form}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_settings'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={formFields} formValues={formValues}/>
        </Form>);
    }

    const renderStyles = () => {
        if (!formFields.length) return null;

        return (<Form
            form={styleForm}
            initialValues={formValues}
            onFinish={onFinish}
            onFinishFailed={onFailed}
            name={section + '_settings'}
            className="margin-top-pm"
            layout="vertical"
        >
            <FormFields t={t} section={section} fields={styleFields} formValues={formValues}/>
        </Form>);
    }

    const renderFunctionBlock = (name = 'pre', alertType = null, icon = null) => {
        // console.log('graphItem', graphItem)
        return (<section id={name} className="margin-bottom-md">
            {RenderSectionTitle(name, icon)}
            <Collapse isOpened={opened[name]}>
                <GraphBlock
                    type="action"
                    ref={graphRef}
                    graphItem={graphItem}
                    blockLocalId={layoutLocalIds[name]}
                    nodesState={nodesState}
                    setNodesState={setNodesState}
                    // onChange={onGraphChange}
                    // onSave={onSave}
                    // forbidden={id && status.sent}
                    // forbiddenMessage={t('mail.error.form.forbidden')}
                />
                {alertType ? <Alert
                    closable={true}
                    type={alertType}
                    className="font-size-sm"
                    message={<span
                        dangerouslySetInnerHTML={createMarkup(t(adminSection + '.section.' + name + '.desc'))}
                    />}
                /> : null}
            </Collapse>
        </section>);
    }

    const renderFunctions = () => {
        return (<Row gutter={50}>
            <Col xs={24} sm={12}>
                {renderFunctionBlock('pre', 'error', <UsbOutlined/>)}

            </Col>
            <Col xs={24} sm={12}>
                {/*{RenderSectionTitle('events', <ThunderboltOutlined/>)}
                <Collapse isOpened={opened.events}>
                    <Form
                        form={eventsForm}
                        onFinish={onFinish}
                        onFinishFailed={onFailed}
                        name={section + '_events'}
                        className="margin-top-pm"
                        layout="vertical"
                    >
                        <FormFields
                            t={t}
                            section={section}
                            fields={eventsFields}
                            formValues={deepGet(formValues, ['params'], {})}
                        />
                    </Form>
                </Collapse>*/}

                {renderFunctionBlock('post', 'info', <MailOutlined/>)}
            </Col>
        </Row>)
    }

    const renderConditions = () => {

        return (<Row gutter={50}>
            <Col xs={24} sm={12}>
                <Form
                    form={authForm}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    name={section + '_auth'}
                    className="margin-top-pm"
                    layout="vertical"
                >
                    {RenderSectionTitle('auth', <LoginOutlined/>)}
                    <Collapse isOpened={opened.auth}>
                        <FormFields
                            t={t}
                            section={section}
                            fields={authFields}
                            formValues={deepGet(formValues, ['params'], {})}
                        />
                    </Collapse>

                    {RenderSectionTitle('access', <UnlockOutlined/>)}
                    <Collapse isOpened={opened.access}>
                        <FormFields
                            t={t}
                            section={section}
                            fields={accessFields}
                            formValues={deepGet(formValues, ['params'], {})}
                        />
                    </Collapse>
                </Form>
            </Col>
            <Col xs={24} sm={12}>
                {renderFunctionBlock('cond', 'warning', <BranchesOutlined/>)}
            </Col>
        </Row>)
    }

    const renderLayout = () => {
        return (<LayoutMaker
            t={t}
            id={id}
            item={item}
            getNewId={getNewBlockId}
            isOn={tab === 'layout'}
            saveItem={() => onFinish(false)}
        />);
    };

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Header className="site-layout-background page-container-horizontal-padding has-banner">
                    <PageHeader
                        title={item.id ?
                            (item.id && item.id === id ?
                                item.title : t('common.action.edit') + ' ' + t(section + '.object.title')) :
                            t('common.action.add') + ' ' + t(section + '.object.title')}
                        className="padding-none-horizontal"
                        extra={[
                            // back
                            <Button
                                key="return-button"
                                className="hidden-sm"
                                type="text"
                                icon={<ArrowLeftOutlined/>}
                                onClick={backToList}
                            >
                                {t('common.action.back')}
                            </Button>,

                            // export
                            <Button
                                key="export-button"
                                type="text"
                                icon={<DownloadOutlined/>}
                                onClick={downloadItem}
                            >
                                <span className="hidden-sm"> {t('common.action.export')}</span>
                            </Button>,

                            // save
                            isAllowed ?  <Button.Group
                                key="primary-set"
                                className="rounded-items-sm"
                            >
                                {id ? <Button
                                    key="save-button"
                                    type="primary"
                                    icon={<CheckOutlined/>}
                                    onClick={() => {
                                        onFinish(false);
                                    }}
                                /> : null}
                                <Button key="primary-button" type="primary" htmlType="submit" onClick={onFinish}
                                        className="hidden-sm">
                                    <span className="hidden-sm-important">{t('common.action.save')}</span>
                                </Button>
                            </Button.Group> : null,
                        ]}
                    />
                </Header>

                <Content
                    id="effects-list-wrapper"
                    className="page-container form-container site-layout-background padding-top-none"
                >
                    <Tabs
                        defaultActiveKey={tab}
                        onChange={setTab}
                        size="large"
                        // className="margin-bottom-sm"
                        items={[
                            {
                                label: <span>
                                    <LaptopOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.layout')}</span>
                                </span>,
                                key: 'layout',
                                // children: renderStyles(),
                            },
                            {

                                label: <span>
                                    <FormOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.form')}</span>
                                </span>,
                                key: 'form',
                                // children: renderFormFields(),
                            },
                            {
                                label: <span>
                                    <LockOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.access')}</span>
                                </span>,
                                key: 'access',
                                // children: renderConditions(),
                            },
                            {
                                label: <span>
                                    <FireOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.functions')}</span>
                                </span>,
                                key: 'functions',
                                // children: renderFunctions(),
                            },
                            {
                                label: <span>
                                    <SettingOutlined/>
                                    <span className="hidden-xs">{t(section + '.tab.settings')}</span>
                                </span>,
                                key: 'settings',
                                // children: renderSettings(),
                            },
                        ]}
                    />

                    <div className={tab === 'layout' ? null : 'hide'}>
                        {renderLayout()}
                        {renderStyles()}
                    </div>
                    <div className={tab === 'form' ? null : 'hide'}>{renderFormFields()}</div>
                    <div className={tab === 'access' ? null : 'hide'}>{renderConditions()}</div>
                    <div className={tab === 'functions' ? null : 'hide'}>{renderFunctions()}</div>
                    <div className={tab === 'settings' ? null : 'hide'}>{renderSettings()}</div>

                    <FormSubmit
                        onSubmit={onFinish}
                        onCancel={backToList}
                        className="margin-top-pm"
                        isAllowed={isAllowed}
                    />
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default LayoutEdit