import React from 'react';
import {Switch} from "antd";
import {FormItemLabel} from "../FormItemLabel";

export const LabelSwitch = (props) => {
    const switchTag = (<>
        <Switch {...props} />
        {props.label}
    </>);

    return (
        <FormItemLabel label={switchTag} />
    );
};

