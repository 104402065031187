import React, {useEffect, useLayoutEffect, useState} from 'react'
import {Form, Input, Layout, Typography} from "antd";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {l} from "../../library/locale";
import {LabelSwitch} from "../../components/Form/Field/LabelSwitch";
import {AvatarUpload} from "../../components/Form/Field/AvatarUpload";
import {createMarkup, setFormField} from "../../library/functions";
import {dotenv, routes} from "../../config/config";
import {FormSubmit} from "../../components/Form/FormSubmit";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {FormTitle} from "../../components/Form/FormTitle";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Banner} from "../../components/Banner/Banner";
import {useTranslation} from "react-i18next";
import {NumberField, TagsField} from "../../components/Form/Field/FormFields";
import {messengers, popularEffectsTypes} from "../../schemas/frontend/effects";
import {specialEventTypes} from "../../schemas/frontend/events";
import {createObjectFromIntegrations} from "../Integrations/IntegrationEdit";

const {Content} = Layout;
const {Title} = Typography;

const ProjectEdit = ({local = false}) => {
    const section = 'project'

    // data from GET params
    const params = useParams()
    const id = Number(params.id || params.project_id)

    // init handlers 
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const {t} = useTranslation();

    // sync with store
    const {admin, project, integration} = useSelector(store => store)
    const item = project.item
    const bots = createObjectFromIntegrations(t, integration.list, false);

    // Upload field
    const [fileList, setFileList] = useState([]);
    const storeImageUrl = (image_url) => setFormField(form, "photo_url", image_url)

    // get form and set values
    const [form] = Form.useForm()
    const [formValues, setFormValues] = useState({
        photo_url: '',
        title: '',
        is_on: false,
        allow_over: false,
        max_credit: 0,
        params: {},
    })

    useEffect(() => {
        const anchor = window.location.hash;
        if (anchor) {
            const element = document.querySelector(anchor);
            if (element) {
                element.scrollIntoView();
            }
        }
    }, []);

    useLayoutEffect(() => {
        // avoid non authorized run
        if (admin.authorized) {
            dispatch({type: 'getProjectItem', admin, id});
            if (integration.list.length === 0 || integration.list[0].project_id !== id) {
                dispatch({
                    type: 'getIntegrationList', admin, filters: {
                        project_id: id,
                        platforms: messengers
                    }
                });
            }
        }
        //eslint-disable-next-line
    }, [admin.authorized, id])

    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized) {
            // set field values if correct data received
            if (item.id === id) {
                setFormValues(item);
                form.setFieldsValue(item)

                // console.log('item', item)
                
                if (item.photo_url) {
                    setFileList([{
                        uid: '-1',
                        name: 'avatar.jpg',
                        status: 'done',
                        url: item.photo_url,
                    }]);
                }
            }

            if (integration.list.length === 0 || integration.list[0].project_id !== id) {
                dispatch({
                    type: 'getIntegrationList', admin, filters: {
                        project_id: id,
                        platforms: messengers
                    }
                });
            }
        }
        //eslint-disable-next-line
    }, [item])

    // useEffect(() => {
    //     if (item.id === id) {
    //         form.setFieldsValue(formValues)
    //         if (formValues.photo_url) {
    //             setFileList([{
    //                 uid: '-1',
    //                 name: 'avatar.jpg',
    //                 status: 'done',
    //                 url: formValues.photo_url,
    //             }]);
    //         }
    //     }
    //     //eslint-disable-next-line
    // }, [item, formValues])

    let returnUrl = routes.project_list;
    if (local) {
        returnUrl = `${routes.project_list}/${id}/about`;
    }

    const onCancel = () => navigate(returnUrl);
    const onFailed = (errorInfo) => console.log('Form Failed:', errorInfo.values);
    const onFinish = (values) => {
        const data = {
            title: values.title,
            photo_url: values.photo_url,
            icon_name: 'icon_name',
            is_on: values.is_on,
            params: values.params,
            allow_over: values.allow_over || false,
            max_credit: values.max_credit || 0,
        }
        if (id) {
            data.id = item.id;
            dispatch({type: 'updateProject', admin, data});
        } else {
            dispatch({type: 'createProject', admin, data});
        }

        navigate(returnUrl);
    }

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <Banner image="tech"/>

                <Form
                    form={form}
                    initialValues={formValues}
                    onFinish={onFinish}
                    onFinishFailed={onFailed}
                    className="" // old-school-container
                    name="settings"
                    layout="vertical"
                >
                    <FormTitle onBack={onCancel} banner="tech">
                        {l('common.action.edit')} {l(section + '.object.title')}
                    </FormTitle>

                    <Content className="page-container form-container site-layout-background">
                        <FormItemWrapper>
                            <AvatarUpload
                                admin={admin}
                                project_id={item.id}
                                fileList={fileList}
                                setFileList={setFileList}
                                storeImageUrl={storeImageUrl}
                                siteSection={section}
                                imageType="avatar"
                                limit={1}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper className='hide'>
                            <Form.Item label={l('project.form.photo_url.label')} name='photo_url'>
                                <Input placeholder={'https://cloud.example.com/image/file.jpg'}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={l('project.form.title.desc')}>
                            <Form.Item
                                label={l('project.form.title.label')}
                                name='title'
                                rules={[{required: true, message: l("error.validation.required")}]}
                            >
                                <Input placeholder={t(section + '.form.title.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper>
                            <Form.Item name="is_on" label='' valuePropName="checked" className="allow-overflow">
                                <LabelSwitch label={l('project.form.is_on.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <Title id="dialogs" level={3}>{t('project.form.tariff.title')}</Title>
                        <div className="margin-bottom-sm"/>

                        <FormItemWrapper desc={l('project.form.allow_over.desc')}>
                            <Form.Item name="allow_over" label='' valuePropName="checked" className="allow-overflow">
                                <LabelSwitch label={l('project.form.allow_over.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={l('project.form.max_credit.desc')}>
                            <NumberField
                                name="max_credit"
                                section={section}
                                placeholder="0"
                                defaultValue={0}
                                step={100}
                                max={5000}
                                min={0}
                                t={t}
                            />
                        </FormItemWrapper>

                        <Title id="dialogs" level={3}>{t('project.form.dialogs.title')}</Title>
                        <div className="margin-bottom-sm"/>

                        <FormItemWrapper desc={l('project.form.dialogs.is_on.desc')}>
                            <Form.Item
                                name={['params', 'dialogs', 'is_on']}
                                label=''
                                valuePropName="checked"
                                className="allow-overflow"
                            >
                                <LabelSwitch label={l('project.form.dialogs.is_on.label')}/>
                            </Form.Item>
                        </FormItemWrapper>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.dialogs.bots.desc'))
                        }/>}>
                            <TagsField
                                t={t}
                                name={'params.dialogs.bots'}
                                label={t('project.form.dialogs.bots.label')}
                                placeholder={t('project.form.dialogs.bots.placeholder')}
                                section={section}
                                values={bots}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.dialogs.events.desc'))
                        }/>}>
                            <TagsField
                                t={t}
                                name={'params.dialogs.events'}
                                label={t('project.form.dialogs.events.label')}
                                placeholder={t('project.form.dialogs.events.placeholder')}
                                section={section}
                                values={{
                                    [specialEventTypes.message]: t('project.form.dialogs.events.value.message_new'),
                                    [specialEventTypes.button]: t('project.form.dialogs.events.value.button_press'),
                                    [specialEventTypes.message_send]: t('project.form.dialogs.events.value.message_send'),
                                    [popularEffectsTypes.message_send]: t('project.form.dialogs.events.value.message_effect'),
                                    [specialEventTypes.bot_start]: t('project.form.dialogs.events.value.bot_start'),
                                    [specialEventTypes.bot_stop]: t('project.form.dialogs.events.value.bot_stop'),
                                    // [specialEventTypes.payment_new]: t('project.form.dialogs.events.value.payment_new'),
                                    // 'order_new',
                                    // 'order_cancel',
                                    // TODO: sync with backend
                                }}
                                // localized={true}
                                // values={[
                                //     'message_new',
                                //     'message_send',
                                // ]}
                            />
                        </FormItemWrapper>

                        <Title id="alerts" level={3}>{t('project.form.alerts.title')}</Title>
                        <div className="margin-bottom-sm"/>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.tg_id.errors.desc', {
                                postProcess: 'sprintf',
                                sprintf: [
                                    `https://t.me/${dotenv.alerts_bot_name}?start=id`,
                                    dotenv.alerts_bot_name
                                ]
                            }))
                        }/>}>
                            <TagsField
                                t={t}
                                form={form}
                                name={'params.alert.tg_id.errors'}
                                features={['adding']}
                                label={l('project.form.tg_id.errors.label')}
                                placeholder={t('project.form.tg_id.errors.placeholder')}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.tg_id.tariff.desc', {
                                postProcess: 'sprintf',
                                sprintf: [
                                    `https://t.me/${dotenv.alerts_bot_name}?start=id`,
                                    dotenv.alerts_bot_name
                                ]
                            }))
                        }/>}>
                            <TagsField
                                t={t}
                                form={form}
                                name={'params.alert.tg_id.tariff'}
                                features={['adding']}
                                label={l('project.form.tg_id.tariff.label')}
                                placeholder={t('project.form.tg_id.tariff.placeholder')}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.border.daily.desc'))
                        }/>}>
                            <TagsField
                                t={t}
                                form={form}
                                name={'params.alert.border.daily'}
                                features={['adding']}
                                label={l('project.form.border.daily.label')}
                                placeholder={t('project.form.border.daily.placeholder')}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.border.permanent.desc'))
                        }/>}>
                            <TagsField
                                t={t}
                                form={form}
                                name={'params.alert.border.balance'}
                                features={['adding']}
                                label={l('project.form.border.balance.label')}
                                placeholder={t('project.form.border.daily.placeholder')}
                            />
                        </FormItemWrapper>

                        <FormItemWrapper desc={<span dangerouslySetInnerHTML={
                            createMarkup(t('project.form.border.days.desc'))
                        }/>}>
                            <TagsField
                                t={t}
                                name={'params.alert.border.days'}
                                values={['0', '1', '3', '5', '7']}
                                label={l('project.form.border.days.label')}
                                placeholder={t('project.form.border.days.placeholder')}
                            />
                        </FormItemWrapper>

                        <FormSubmit onCancel={onCancel}/>
                    </Content>
                </Form>
            </Layout>
        </AppWrapper>
    )
}

export default ProjectEdit