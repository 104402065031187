import {put, takeEvery} from 'redux-saga/effects'
import {backend_api} from "../../config/config";
import {apiErrorNotice, apiFalseNotice} from "../../library/error";
import {request} from "../../library/api";
import {
    addSectToList,
    fillSectList,
    patchSectInList,
    removeSectFromList,
    setSectItem,
    setSectListCount,
    setSectStoreParam,
} from "../reducers/SectReducer";

// init api methods
const base_path = backend_api.section;

function* getSectData(arg) {
    try {
        const section = arg.section;
        const filters = {section: section, ...(arg.filters || {})}

        const response = yield request(arg.admin.token, base_path, 'get', filters);
        if (response.data.ok) yield put(setSectStoreParam({[section]: response.data.result}))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSectList(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'get', arg.filters);

        if (response.data.ok) {
            yield put(fillSectList(response.data.result))
            yield put(setSectListCount(response.data.count))
        }
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* getSectItem(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'get',);
        if (response.data.ok) yield put(setSectItem(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* createSect(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'post', arg.data);

        if (response.data.ok) {
            yield put(addSectToList(response.data.result))
            yield put(setSectItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* updateSect(arg) {
    try {
        const response = yield request(arg.admin.token, base_path, 'patch', arg.data);

        if (response.data.ok) {
            yield put(patchSectInList(response.data.result))
            yield put(setSectItem(response.data.result))
        } else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* copySect(arg) {
    try {
        const originalItem = yield request(arg.admin.token, base_path + '/' + arg.id, 'get');
        if (!originalItem.data.ok) apiFalseNotice(originalItem)

        let newItem = originalItem.data.result
        delete newItem.id

        newItem.title += ' - Copy'
        newItem.name += '/copy'
        newItem.order += 1

        const response = yield request(arg.admin.token, base_path, 'post', newItem);
        if (response.data.ok) {
            yield put(setSectItem(response.data.result))
            yield put(addSectToList(response.data.result))
        } else apiFalseNotice(response)

    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* restoreSect(arg) {
    try {
        const data = {deleted_at: 0, ...arg.data}
        const response = yield request(arg.admin.token, base_path, 'patch', data);
        if (response.data.ok) yield put(removeSectFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

function* deleteSect(arg) {
    try {
        const response = yield request(arg.admin.token, base_path + '/' + arg.id, 'delete');

        if (response.data.ok) yield put(removeSectFromList(response.data.result))
        else apiFalseNotice(response)
    } catch (error) {
        apiErrorNotice(error)
        return null
    }
}

export function* sectSaga() {
    yield takeEvery('getSectData', getSectData);
    yield takeEvery('getSectList', getSectList);
    yield takeEvery('getSectItem', getSectItem);
    yield takeEvery('createSect', createSect);
    yield takeEvery('copySect', copySect);
    yield takeEvery('updateSect', updateSect);
    yield takeEvery('restoreSect', restoreSect);
    yield takeEvery('deleteSect', deleteSect);
}

