import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {Layout, Menu} from "antd";

import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ListHeader} from "../../components/List/Header/ListHeader";
import {ListFolderManager} from "../../components/List/Folder/ListFolderManager";
import {GraphListTable} from "./GraphListTable";

import {checkAccessOrRedirect} from "../../library/access";
import {onFolderOpen} from "../../library/functions";
import {useTranslation} from "react-i18next";
import {second_graph} from "../../schemas/backend/second_graph";
import {UploadOutlined} from "@ant-design/icons";
import {notice} from "../../library/notice";
import ImportModal from "../../components/Modal/ImportModal";

const {Content} = Layout;

const GraphList = () => {
    // init section name
    const section = 'graph'
    const default_ordering = '-created_at';

    const {t} = useTranslation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const folder_id = searchParams.get('folder') ? Number(searchParams.get('folder')) : 0;

    // store and state
    const {admin, project, graph} = useSelector(store => store)
    const [ordering, setOrdering] = useState('default')
    const [filters, setFilters] = useState({})

    const [fileList, setFileList] = useState([]);
    const [fileContent, setFileContent] = useState(null);
    const [isVisibleModal, setVisibleModal] = useState(false);

    // init universal vars
    let project_item = project.item
    let list = graph.list

    // get data from API
    useEffect(() => {
        if (admin.authorized && project_item.id) {
            const access = checkAccessOrRedirect(project_item, section, navigate, t);

            if (access) {
                const order = (ordering === 'default') ? default_ordering : ordering
                let filters_result = {project_id: project_item.id, ordering: order}
                if (filters) filters_result = {...filters_result, ...filters}
                dispatch({type: 'getGraphList', admin, filters: filters_result})
            }
        }

        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, filters, ordering])


    const searchHandler = (value) => {
        let filters_result = {title: value}
        if (filters) filters_result = {...filters, ...filters_result}
        setFilters({...filters, ...filters_result})
    }

    const recordCreate = (folder_id = 0, title = null, logic = null) => {
        let nextGraphNum = list.length + 1;

        const data = {
            project_id: project_item.id,
            folder_id: folder_id,
            title: t(section + '.item.title') + ' ' + nextGraphNum,
            icon_name: 'square-terminal',
            logic: second_graph,
            is_on: false,
        }
        // if (!objectLength(filters) && !list.length) {
        //     data['title'] = t(section + '.item.example');
        //     data['logic'] = first_graph;
        // }

        // for import
        if (title) data['title'] = title;
        if (logic) data['logic'] = logic;

        dispatch({type: 'createGraph', admin, data});

        // and open folder after create
        if (typeof folder_id !== 'number') folder_id = 0
        onFolderOpen(folder_id, true, searchParams, setSearchParams)
    }

    const recordCreateCommon = () => {
        // required for button onclick function
        recordCreate(folder_id)
    }

    const filterFields = [
        [
            {
                name: 'created',
                type: 'date-range',
            },
            {
                name: 'updated',
                type: 'date-range'
            },
            {
                name: 'deleted',
                type: 'date-range'
            },
            {
                name: 'is_on',
                type: 'menu',
                localized: true,
                values: ['on', 'off']
            }
        ],
        [
            {
                name: 'is_deleted',
                type: 'switcher'
            }
        ]
    ]

    const recordImport = () => {
        if (!fileContent) {
            notice.info(t('graph.import.error.empty'));
            return;
        }
        try {
            const newGraph = JSON.parse(fileContent);
            if (!newGraph.logic) {
                notice.error(t('graph.import.error.logic'));
                return
            }
            console.log('Import Graph', newGraph.logic);
            recordCreate(folder_id, newGraph.title, newGraph.logic);
        } catch (e) {
            console.log('Graph data parse error', e);
            notice.error(t('graph.import.error.format'));
        }
    };

    const onMenuClick = (e) => {
        if (e.key === 'import') {
            setVisibleModal(true);
            setFileList([]);
            setFileContent('');
        }
    };

    const createMenu = (<Menu
            onClick={onMenuClick}
            items={[
                {
                    key: 'import',
                    label: t('common.action.import'),
                    icon: <UploadOutlined/>
                },
            ]}
        />
    );

    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background">
                <ListHeader
                    section={section}
                    filters={filters}
                    setFilters={setFilters}
                    filterFields={filterFields}
                    onSearch={searchHandler}
                    onPrimary={recordCreateCommon}
                    primaryMenu={createMenu}
                    primaryLabel={t('common.action.add') + ' ' + t(section + '.object.title')}
                />
                <Content className="page-container site-layout-background">
                    <GraphListTable
                        admin={admin}
                        project={project_item}
                        section={section}
                        list={list}
                        filters={filters}
                        setFilters={setFilters}
                        setOrdering={setOrdering}
                        recordCreate={recordCreate}
                    />
                    <ListFolderManager section={section} project_item={project_item} list={list}/>
                </Content>

                <ImportModal
                    t={t}
                    onOk={recordImport}
                    section={section}
                    isVisibleModal={isVisibleModal}
                    setVisibleModal={setVisibleModal}
                    fileList={fileList}
                    setFileList={setFileList}
                    fileContent={fileContent}
                    setFileContent={setFileContent}
                    fileFormat="json"
                />
            </Layout>
        </AppWrapper>
    )
}
export default GraphList

// ant-table-cell cursor-pointer table-row-title title-link ant-table-column-sort
// ant-table-cell cursor-pointer table-row-title title-link