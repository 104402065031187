import React, {useEffect} from 'react'
import classes from './LoginAdmin.module.scss'
import {Form, Input, Button, Checkbox} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dotenv, images, routes} from "../../config/config";
import {useTranslation} from "react-i18next";
import Scrollbar from "react-scrollbars-custom";
import {setLogging} from "../../redux/reducers/AdminReducer";
import {NavigateLink} from "../../components/Navigate/Navigate";

const RegFormAdmin = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [checked, setChecked] = React.useState(false) // Checkbox = true => checked
    const {admin} = useSelector(store => store)

    useEffect(() => {
        dispatch(setLogging(true));
        //eslint-disable-next-line
    }, [])

    useEffect(() => {
        // console.log('useEffect', admin.authorized, admin.correct, !admin.logging, admin.user.id)
        if (admin.authorized && admin.correct && !admin.logging && admin.user.id) navigate(routes.welcome)
        //eslint-disable-next-line
    }, [admin])

    if (!dotenv.is_registration_allowed) {
        navigate(routes.auth);
        return null;
    }

    const onFinish = (values) => dispatch({type: 'createAdmin', values})
    const onFinishFailed = (errorInfo) => console.log('Forms Failed:', errorInfo.values);
    const onChange = (e) => setChecked(e.target.checked);

    const onChangePassword = (e) => {
        const lp = document.getElementById('input_field_password')
        if (lp) {
            const length = e.target.value.length
            const count = lp.parentNode.querySelector('.ant-input-show-count-suffix')
            if (count) {
                if (length < 5) count.style.color = 'red'; else count.style.color = ''
            }
        }
    }

    return (
        <Scrollbar>
            <div className={`${classes.container} ${classes.container_reg}`}>
                <div className={classes.form_container}>
                    <div className={classes.wrapper_form}>
                        <a href={routes.root} className={classes.form_logo_multy}>
                            <img src={images.logo_small} alt='logo color'/>
                        </a>
                        <div className={classes.form_head}>{t('reg.list.title')}</div>
                        {/*<div className={classes.reg_social_linkblock}>*/}
                        {/*    <Link className={classes.reg_social} to='/'>*/}
                        {/*        <div className={`${classes.wrapper_icon} ${classes.wrapper_icon_vk}`}>*/}
                        {/*            <FaVk className={classes.faIcon}/>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.reg_social_text}>{t('platform.vk.title')}</div>*/}
                        {/*    </Link>*/}
                        {/*    <Link className={classes.reg_social} to='/'>*/}
                        {/*        <div className={`${classes.wrapper_icon} ${classes.wrapper_icon_tg}`}>*/}
                        {/*            <FaTelegramPlane className={`${classes.faIcon} ${classes.faTg}`}/>*/}
                        {/*        </div>*/}
                        {/*        <div className={classes.reg_social_text}>{t('platform.tg.title')}</div>*/}
                        {/*    </Link>*/}
                        {/*</div>*/}
                        {/*<div className={classes.hor_line}>*/}
                        {/*    <div className={classes.hor_line_title}>{t('common.or')}</div>*/}
                        {/*</div>*/}
                        <Form
                            className={classes.auth_form}
                            name='basic'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete='off'
                            initialValues={{
                                policy: checked,
                                search_query: window.location.search ? window.location.search.substring(1) : '',
                            }}
                        >
                            <Form.Item
                                name='first_name'
                                className={`${classes.form_item} ${classes.form_item_fields}`}
                                rules={[{required: true, message: t('auth.fields.login.error.required')}]}
                            >
                                <Input
                                    // data-lpignore='true'
                                    className={classes.input_field}
                                    type={'text'}
                                    placeholder={t('auth.fields.name.title')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='email'
                                className={`${classes.form_item} ${classes.form_item_fields}`}
                                rules={[{required: true, message: t('auth.fields.email.error.required')}]}>
                                <Input
                                    // data-lpignore='true'
                                    className={classes.input_field} type={'text'}
                                    placeholder={t('auth.fields.email.title')}
                                />
                            </Form.Item>
                            <Form.Item
                                name='password'
                                className={`${classes.form_item} ${classes.form_item_fields}`}
                                rules={[{
                                           required: true,
                                           min: 5,
                                           message: t('auth.fields.password.error.required')
                                       }]}
                            >
                                <Input.Password
                                    id='input_field_password'
                                    data-lpignore='true'
                                    showCount /*minLength={5}*/
                                    className={classes.input_field}
                                    type={'password'}
                                    placeholder={t('auth.fields.password.password_symbol')}
                                    onChange={onChangePassword}
                                />
                            </Form.Item>
                            <div id='length_password' className={classes.password_length}>
                                {t('auth.fields.password.error.password_symbol_count')} (<span
                                id='current_length_password'>0</span>/<span>5</span>)
                            </div>

                            <Form.Item
                                name='search_query'
                                className="hide"
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                className={classes.form_check_item}
                                name="policy"
                                valuePropName="checked"
                                onChange={onChange}
                                rules={[{required: true, message: t('auth.fields.checkbox.error.required')}]}
                            >
                                <div className={classes.form_check_box}>
                                    <Checkbox>
                                        <span>{t('auth.links.checkbox.action')} </span>
                                        <NavigateLink link={routes.home + routes.privacy} external={true}>
                                            {t('auth.links.checkbox.link')}
                                        </NavigateLink>
                                    </Checkbox>
                                </div>
                            </Form.Item>

                            <Form.Item className={classes.form_item}>
                                <div className={classes.wrapper_buttons}>
                                    <Button className={classes.form_button_submit} type="primary" htmlType="submit"
                                            disabled={!checked} style={{color: checked ? '#fff' : ''}}>
                                        {t('auth.fields.submit.title')}
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        <div className={classes.have_account}>
                            <div className={classes.have_account_answer}>{t('auth.links.login.answer')}
                            </div>
                            <div className={classes.have_account_action}
                                 onClick={() => {
                                     navigate(routes.auth)
                                 }}>{t('auth.links.login.action')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Scrollbar>
    )
}

export default RegFormAdmin