import {dotenv, routes} from "../config/config";
import {deepGet, getUtcDateTime, inArray} from "./functions";
import {notice} from "./notice";

export const isTariffDeveloper = (project) => {
    return deepGet(project, 'params.tariff.type', 'business') === 'developer';
}

export const checkAccessOrRedirect = (project, section, navigate, t) => {
    if (dotenv.is_app_free) return true

    if (!project || !project.id) navigate(dotenv.main_page);

    let is_section_allowed = false
    // const now = getUtcDateTime()

    if (project.sections && project.sections.length) {
        is_section_allowed = inArray(section, project.sections);
    }

    if (!is_section_allowed) {
        notice.warning(t('error.section_access'))
        navigate(`${routes.project_list}/${project.id}/tariff`);
        return false
    }

    return true
}