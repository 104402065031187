import React, {useEffect, useRef, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {dotenv, routes} from "../../config/config";
import {
    Button,
    Input,
    Col,
    Form,
    Layout,
    Row,
    Tooltip,
    Typography,
    InputNumber,
    Slider,
    Radio,
    Popconfirm,
    Carousel,
} from "antd";
import {useDispatch, useSelector} from "react-redux";

import {
    deepGet,
    redirect,
    inArray,
    addUniqueValueToArray,
    removeValueFromArray,
    roundPrice,
    numberForHuman,
    createMarkup,
    toNum,
} from "../../library/functions";

import Fa from "../../components/Icon/Fa";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {useTranslation} from "react-i18next";
import {CustomHeader} from "../../components/Layout/CustomHeader";
import {ContentCard} from "../../components/Card/Content/ContentCard";
import {
    CheckOutlined,
    PlusOutlined,
    QuestionCircleOutlined,
    ShoppingOutlined,
} from "@ant-design/icons";
import {reachGoal} from "../../library/metrics";
import InputModal from "../../components/Modal/InputModal";
import {SimpleModal} from "../../components/Modal/SimpleModal";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {FormItemLabel} from "../../components/Form/FormItemLabel";
import {NoForm} from "../../components/Form/NoForm";
import {notice} from "../../library/notice";
import {LabelSwitch} from "../../components/Form/Field/LabelSwitch";

const {Content} = Layout;
const {Title, Text} = Typography;

const Tariff = () => {
    const {t} = useTranslation()
    const section = 'tariff'

    // data from URL params
    const params = useParams()
    const project_id = Number(params.project_id)

    // data from URL params
    // const params = useParams()
    // const project_id = Number(params.project_id)

    // init hooks
    const dispatch = useDispatch()
    const navigate = useNavigate()

    navigate(`${routes.project_list}/${project_id}${routes.local.about}`)

    // const [checkedSections, setCheckedSections] = useState(['graph', 'templates', 'gamification', 'sales'])
    const [aiForm] = Form.useForm();
    const [orderForm] = Form.useForm();
    const [checkedSections, setCheckedSections] = useState([])
    const [selectedTariff, setSelectedTariff] = useState(null)
    const [pageParams, setPageParams] = useState({
        messages: {
            limit: 0,
            batch: 0,
        },
        amount: {
            messages: 1000,
            pages: 10,
            space: 10,
            months: 1,
            order: 0,
            project: 0,
            discount: 0,
        },
        ai: {
            amount: 0,
            from_project: false,
        },
        order: {
            phone: '',
            auto: false,
            subscribe: false,
            to_project: true,
        },
        modal: {
            tariff: false,
            order: false,
            promo: false,
            project: false,
            ai: false,
        },
        view: {
            tight: false,
        },
        tariff: {
            type: null,
            selected: null,
        },
        promo: {
            show: false,
            bonus: 10,
            code: '',
            name: '',
            value: 0,
        },
    })

    // sync with store
    const {admin, project, userField, order, discount, sect} = useSelector(store => store)
    const carouselRef = useRef(null);

    const project_item = project.item
    const balance = deepGet(userField, 'balance.value', 0)
    const discountItem = discount.list.length ? discount.list[0] : {'id': 0, 'amount': 10, 'name': ''}

    const tariffs = sect.tariff;
    const projectTariffType = project_item.tariff;
    console.log('project Tariff Type 1', projectTariffType)

    // const now = getUtcDateTime();
    // const tariffExpired = project_item.tariff_expired_at || 0;
    // if (tariffExpired < now) projectTariffType = 'free';

    useEffect(() => {
        if (admin.authorized && admin.token) {
            dispatch({type: 'getSectData', admin, section: 'tariff', filters: {is_on: true, page_size: 0}});

            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: ['balance'],
                }
            })

            dispatch({
                type: 'getDiscountList',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    product_id: dotenv.main_product,
                    partner_user_ids: [admin.user.id],
                    page_size: 1
                }
            })
        }
        //eslint-disable-next-line
    }, [admin])

    useEffect(() => {
        console.log('projectTariffType', projectTariffType)

        // setState('tariff.type', projectTariffType)
        // setState('tariff.selected', projectTariffType)
        setSelectedTariff(projectTariffType)

        const activeIndex = tariffs.findIndex(tariff => projectTariffType === tariff.name);
        if (carouselRef.current && activeIndex !== -1) {
            carouselRef.current.goTo(activeIndex, true); // Set exact slide
        }

    }, [projectTariffType, tariffs])

    useEffect(() => {
        if (order.url) redirect(order.url)
        // if (order.url) console.log('order.url', order.url)
        // else if (order.url && !order.loading.balance) dispatch(setOrderUrl(''))
        //eslint-disable-next-line
    }, [order.url])

    useEffect(() => {
        if (discountItem.id) {
            // console.log('discountItem.id', discountItem.id)
            // setState('promo.name', discountItem.name)
            // setState('promo.bonus', discountItem.amount)

            setPageParams({
                ...pageParams,
                promo: {
                    ...pageParams.promo,
                    name: discountItem.is_on ? discountItem.name : '',
                    bonus: discountItem.amount,
                }
            })
        }
        //eslint-disable-next-line
    }, [discountItem.id])

    // local locale func
    const l = (c) => t('project.' + section + '.' + c);

    const setState = (key, value) => {
        // console.log('setState', key, value)

        const keys = key.split('.');
        const firstKey = keys.shift();
        const lastKey = keys.pop();

        let newSection = {...pageParams[firstKey]};
        newSection[lastKey] = value;
        setPageParams({...pageParams, [firstKey]: newSection});
    }

    const onTariffFormSubmit = () => {
        // const tariffPrice = getTariffPrice();
        // console.log('onTariffFormSubmit', tariffPrice)

        const tariffData = {
            project_id: project_item.id,
            sections: checkedSections,
            tariff_name: selectedTariff,
            daily_count: pageParams.amount.messages,
            month_count: pageParams.amount.months,
            pages_amount: pageParams.amount.pages,
            space_amount: pageParams.amount.space,
        }

        // catch metrics goal
        reachGoal('tariff', tariffData)

        dispatch({
            type: 'purchaseAppTariff',
            admin,
            data: tariffData
        })

        setPageParams({
            ...pageParams,
            messages: {...pageParams.messages, limit: 0, batch: 0},
            amount: {...pageParams.amount, months: 1, order: 0, project: 0, discount: 0},
        })
        setCheckedSections([])
    }

    const onOrderFormSubmit = () => {
        // console.log('onOrderFormSubmit', value)

        // catch metrics goal
        reachGoal('order', {order_price: pageParams.amount.order})

        const orderValues = orderForm.getFieldsValue();
        // console.log('orderValues', orderValues)
        const data = {
            // title: l('account.amount.product_title'),
            price: orderValues.amount,
            promo: orderValues.promo,
            user_phone: orderValues.phone,
            related_project_id: project_item.id,
            subscribe: pageParams.order.subscribe,
            renew_tariff: pageParams.order.auto,
            to_project: pageParams.order.to_project,
        }
        // console.log('onOrderFormSubmit data', data)
        dispatch({type: 'createAppOrder', admin, data})
    }

    const onOk = () => {
        orderForm.submit()
    }

    const setProjectTariff = () => {
        dispatch({
            type: 'setAppTariff',
            admin,
            data: {
                project_id: project_item.id,
                tariff: selectedTariff,
            }
        })
    }

    const onAiOk = () => {
        aiForm.submit()
    }

    const onAiFormSubmit = (values) => {
        // console.log('onAiFormSubmit values', values)
        dispatch({
            type: 'increaseProjectAiBalance',
            admin,
            data: {
                project_id: project_item.id,
                amount: values.amount,
                from_project: !!values.from_project
            }
        })
    }

    const onProjectFormSubmit = (value) => {
        // console.log('onProjectFormSubmit', pageParams.amount.project, value)

        // catch metrics goal
        reachGoal('projectBalance', {order_price: value})

        dispatch({
            type: 'increaseProjectBalance',
            admin,
            data: {
                project_id: project_item.id,
                amount: value,
            }
        })
    }

    const onPromoSave = () => {
        // console.log('promoSave', pageParams.promo.name, pageParams.promo.bonus)
        if (!admin.user.id) {
            notice.error('Admin not authorized')
            return
        }

        const discountData = {
            project_id: dotenv.main_project,
            product_id: dotenv.main_product,
            title: l('account.discount.item.title') + ': ' + pageParams.promo.name,
            // name: pageParams.promo.name,
            amount: pageParams.promo.bonus,
            partner_user_id: admin.user.id,
            mode: 'percent',
            params: {
                bonus: {
                    partner: {
                        currency: 'coins',
                        mode: 'percent',
                        amount: Math.round((20 - pageParams.promo.bonus) * 10, 2),
                        limit: 0,
                    }
                }
            }
        }

        if (discountItem.id) {
            if (pageParams.promo.name) {
                discountData['is_on'] = true
                discountData['name'] = pageParams.promo.name
            } else discountData['is_on'] = false

            dispatch({
                type: 'updateDiscount', admin, data: {
                    id: discountItem.id,
                    ...discountData
                }
            })
        } else {
            if (!pageParams.promo.name) {
                notice.info(l('account.promo.error.name_is_empty'))
                return
            }
            discountData['name'] = pageParams.promo.name
            dispatch({type: 'createDiscount', admin, data: discountData})
        }
    }

    const onSectionChecked = (value, event) => {
        // console.log(event?.target?.name)
        const section_name = 'graph'; // event.target.name;
        let checked_sections = [...checkedSections];
        // console.log('event.target.checked', event.target.checked)
        if (value) checked_sections = addUniqueValueToArray(section_name, checked_sections)
        else checked_sections = removeValueFromArray(section_name, checked_sections)
        // console.log('checked_sections', checked_sections)
        setCheckedSections(checked_sections)
    }

    const isDeveloper = projectTariffType === 'developer' || pageParams.tariff.type === 'developer';
    const sectionsRows = [
        // TODO: remove after fix by tariffs
        {
            key: 'graph',
            title: l('access.section.graph'),
            price: isDeveloper ? dotenv.price['graph'][1] : dotenv.price['graph'][0],
            disabled: projectTariffType !== pageParams.tariff.type,
        },
        /*{
            key: 'page',
            title: l('access.section.page'),
            price: isDeveloper ? dotenv.price['page'][1] : dotenv.price['page'][0],
            disabled: projectTariffType !== pageParams.tariff.type,
        },*/
    ];

    const proj_balance = parseFloat(toNum(project_item.balance))
    const ai_balance = parseFloat(toNum(project_item.ai_balance))

    const getTariffPrice = () => {
        let price = 0;
        // TODO: FIX by tariffs
        if (selectedTariff === 'custom') {
            price = pageParams.amount.messages * dotenv.daily_price
            for (const checkedSection of checkedSections) {
                const sect = sectionsRows.find(e => e.key === checkedSection)
                // console.log('sect', sect, checkedSection)
                if (sect) price += sect.price
            }

            price += pageParams.amount.pages * dotenv.page_price;
            price += pageParams.amount.space * dotenv.space_price;

            // if (!inArray(pageParams.tariff.type, ['free', 'custom']) && pageParams.amount.months > 1) {
            //     if (pageParams.amount.months > 10) price *= 0.8
            //     else if (pageParams.amount.months > 5) price *= 0.9
            // }

            price *= pageParams.amount.months
        } else {
            const tariff = tariffs.find(e => e.name === selectedTariff);
            if (tariff && tariff.spec.price) price = tariff.spec.price[String(pageParams.amount.months)]
        }

        let amount = price - proj_balance
        if (amount < 0) amount = 900

        orderForm.setFieldValue('amount', Math.round(amount + 100));
        return price
    }

    const tariffPrice = getTariffPrice();
    const isEnough = tariffPrice <= proj_balance;
    // const orderPrice = tariffPrice - proj_balance - balance - coins_price;

    let admin_phones = []
    if (admin.user && admin.user.contacts) admin_phones = admin.user.contacts.filter(e => e.type === 'phone')
    const admin_phone = admin_phones.length ? admin_phones[0].value : ''

    const showOrderModal = () => {
        const currentValues = orderForm.getFieldsValue();

        const newValues = {
            amount: currentValues.amount > 0 ? currentValues.amount : Math.round(tariffPrice - proj_balance + 100),
            phone: currentValues.phone ?? admin_phone,
        }

        orderForm.setFieldsValue(newValues);
        setPageParams({
            ...pageParams,
            amount: {...pageParams.amount, order: newValues.amount},
            modal: {...pageParams.modal, order: true},
        })
    }

    const onOrderFormChange = (changedValues) => {
        if (changedValues.amount !== undefined) setState('amount.order', toNum(changedValues.amount));
    }

    const onAiFormChange = (changedValues) => {
        if (changedValues.amount !== undefined) setState('ai.amount', toNum(changedValues.amount));
    }

    // console.log('tariffs', tariffs)

    const isCustom = selectedTariff === 'custom';  // custom
    const selectTariff = (tariff) => {
        if (!tariff || !tariff.spec) {
            console.info('Select Tariff: record not found')
            return
        }

        setPageParams({
            ...pageParams,
            tariff: {
                ...pageParams.tariff,
                selected: tariff.name,
            },
            amount: {
                ...pageParams.amount,
                messages: tariff.spec.messages || 1000000,
                pages: tariff.spec.pages || 10000,
                space: tariff.spec.space,
            }
        })

        setCheckedSections(['graph'])
    }

    const carouselOnChange = (index) => {
        setPageParams({
            ...pageParams,
            tariff: {
                ...pageParams.tariff,
                selected: tariffs[index].name,
            }
        })
    }

    // console.log('projectTariffType', projectTariffType, 'selectedTariff', selectedTariff)

    const renderApplyButton = () => {
        if (projectTariffType === 'free' && selectedTariff === 'free') return null;

        if (!isEnough && tariffPrice) {
            return <div className="inline">
                <Button
                    type="primary"
                    size="large"
                    className="height-auto margin-right-sm margin-bottom-ps visible-xxl"
                    loading={order.loading.balance}
                    icon={<ShoppingOutlined/>}
                    onClick={showOrderModal}
                >{l('account.balance.modal.title')}</Button>

                <Button
                    type="primary"
                    size="default"
                    className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                    loading={order.loading.balance}
                    icon={<ShoppingOutlined/>}
                    onClick={showOrderModal}
                >{l('account.balance.modal.title')}</Button>
            </div>
        }

        if (projectTariffType !== selectedTariff) {
            return <div className="inline">
                <Popconfirm
                    title={l('calc.pay.confirm')}
                    onConfirm={pageParams.tariff.type === 'free' ? onTariffFormSubmit : setProjectTariff}
                    // onConfirm={setProjectTariff}
                    icon={null}
                    placement="topLeft"
                    okText={t('common.yes')}
                    cancelText={t('common.no')}
                    cancelButtonProps={{type: 'text'}}
                    getPopupContainer={() => document.getElementById('app-page-wrapper')}
                >
                    <Button
                        // style={{marginTop: 6}}
                        className="height-auto margin-right-sm visible-xxl"
                        type="primary"
                        size="large"
                        loading={order.loading.messages}
                        // disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                        icon={<CheckOutlined/>}
                    >{l('calc.pay.change')}</Button>

                    <Button
                        // style={{marginTop: 6}}
                        className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                        type="primary"
                        size="default"
                        loading={order.loading.messages}
                        // disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                        icon={<CheckOutlined/>}
                    >{l('calc.pay.change')}</Button>
                </Popconfirm>
            </div>
        }

        return <Tooltip
                title={isEnough && tariffPrice ? '' : l('calc.pay.forbidden')}
                placement="topLeft"
            >

                <div className="inline">
                    <Popconfirm
                        title={l('calc.pay.confirm')}
                        onConfirm={onTariffFormSubmit}
                        icon={null}
                        placement="topLeft"
                        okText={t('common.yes')}
                        cancelText={t('common.no')}
                        cancelButtonProps={{type: 'text'}}
                        getPopupContainer={() => document.getElementById('app-page-wrapper')}
                    >
                        <Button
                            // style={{marginTop: 6}}
                            className="height-auto margin-right-sm visible-xxl"
                            type="primary"
                            size="large"
                            loading={order.loading.messages}
                            disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                            icon={<CheckOutlined/>}
                        >{l('calc.pay.apply')}</Button>

                        <Button
                            // style={{marginTop: 6}}
                            className="height-auto margin-right-sm margin-bottom-ps hidden-xxl"
                            type="primary"
                            size="default"
                            loading={order.loading.messages}
                            disabled={!isEnough || !tariffPrice || projectTariffType !== pageParams.tariff.type}
                            icon={<CheckOutlined/>}
                        >{l('calc.pay.apply')}</Button>
                    </Popconfirm>
                </div>
            </Tooltip>

    }

    return (
        <AppWrapper className="bg-gray-light">
            <Layout className="site-layout site-layout-transparent">
                <CustomHeader
                    key="tariff-header"
                    className={'padding-none-horizontal'}
                    title={l('title')}
                />
                <Content className="page-container container-with-cards padding-none-vertical">
                    <Row gutter={30} align="stretch">
                        {/* left column start */}
                        <Col xs={24} lg={15} className="margin-top-pm xs-margin-top-none">
                            <div className="visible-lg">
                                <Row gutter={30} align="stretch">
                                    {tariffs && tariffs.map((tariff, index) => {
                                        const isCurrent = projectTariffType === tariff.name;
                                        const isSelected = selectedTariff === tariff.name;

                                        return <Col key={index} xs={24} lg={12} className="margin-bottom-ps">
                                            <ContentCard
                                                className="full-height"
                                                onClick={() => selectTariff(tariff)}
                                                interactive={true}
                                                active={isSelected}
                                            >
                                                <Title level={2}
                                                    // className={isCurrent || isSelected ? null : 'color-secondary'}
                                                >
                                                    {isCurrent ? <Tooltip title={l('cards.active')}>
                                                        <CheckOutlined className="margin-right-ps"/>
                                                    </Tooltip> : null}
                                                    {t('tariff.' + tariff.name + '.title')}
                                                </Title>
                                                <div dangerouslySetInnerHTML={
                                                    createMarkup(t('tariff.' + tariff.name + '.desc'))
                                                }/>
                                            </ContentCard>
                                        </Col>
                                    })}
                                </Row>
                            </div>

                            <div className="margin-bottom-pm padding-left-xp padding-right-xp hidden-lg">
                                <Carousel ref={carouselRef} afterChange={carouselOnChange} className="white">
                                    {tariffs && tariffs.map((tariff, index) => {
                                        const isCurrent = pageParams.tariff.type === tariff.name;
                                        return <ContentCard
                                            key={index}
                                            className="full-height"
                                            flat_xs={true}
                                        >
                                            <Title level={2}>
                                                {isCurrent ? <Tooltip title={l('cards.active')}>
                                                    <CheckOutlined className="margin-right-ps"/>
                                                </Tooltip> : null}
                                                {t('tariff.' + tariff.name + '.title')}
                                            </Title>
                                            <div dangerouslySetInnerHTML={
                                                createMarkup(t('tariff.' + tariff.name + '.desc'))
                                            }/>
                                        </ContentCard>
                                    })}
                                </Carousel>
                            </div>
                        </Col>
                        {/* right column start */}
                        <Col xs={24} lg={9} className="margin-top-pm xs-margin-top-none" id="tariff-column">
                            <ContentCard className="full-height">
                                {/* Калькулятор тарифа */}
                                <Title
                                    level={2}>{l('calc.heading')} {t('tariff.' + projectTariffType + '.title')}</Title>
                                <div
                                    // className={isDeveloper ? 'hide' : ''}
                                >
                                    <div className="margin-top-ps"/>
                                    {pageParams.amount.messages === 1000000 ? <Fa
                                        wcn="float-right align-right color-gray-6"
                                        set="regular"
                                        icon="infinity"
                                    /> : <InputNumber
                                        inputMode="numeric"
                                        className="float-right align-right always-show-arrows clear-disabled"
                                        bordered={false}
                                        disabled={!isCustom}
                                        min={isCustom ? 1000 : 0}
                                        step={500}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('amount.messages', value)}
                                        value={pageParams.amount.messages}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />}

                                    <Tooltip title={l('calc.messages_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.limit')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    <Slider
                                        className="primary"
                                        disabled={!isCustom}
                                        min={isCustom ? 1000 : 0}
                                        max={100000}
                                        step={500}
                                        onChange={(value) => setState('amount.messages', value)}
                                        value={pageParams.amount.messages}
                                    />

                                    <div className="margin-top-ps"/>
                                    {pageParams.amount.pages === 10000 ? <Fa
                                        wcn="float-right align-right color-gray-6"
                                        set="regular"
                                        icon="infinity"
                                    /> : <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="float-right align-right always-show-arrows clear-disabled"
                                        bordered={false}
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        step={10}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('amount.pages', value)}
                                        value={pageParams.amount.pages}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />}
                                    <Tooltip title={l('calc.pages_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.pages')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    <Slider
                                        className="primary"
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        max={1000}
                                        step={10}
                                        onChange={(value) => setState('amount.pages', value)}
                                        value={pageParams.amount.pages}
                                    />

                                    <div className="margin-top-ps"/>
                                    <InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="float-right align-right always-show-arrows clear-disabled"
                                        bordered={false}
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        step={10}
                                        placeholder="1000"
                                        // formatter={(value) => `${value}/д.`}
                                        onChange={(value) => setState('amount.space', value)}
                                        value={pageParams.amount.space}
                                        style={{
                                            width: 100,
                                            maxWidth: '50%',
                                        }}
                                    />
                                    <Tooltip title={l('calc.space_desc')} placement="topLeft">
                                        <Text className="margin-right-xs">{l('calc.space')} </Text>
                                        <Text type="secondary"><QuestionCircleOutlined className="font-size-xs"/></Text>
                                    </Tooltip>
                                    <Slider
                                        className="primary"
                                        disabled={!isCustom}
                                        min={isCustom ? 10 : 0}
                                        max={1000}
                                        step={10}
                                        onChange={(value) => setState('amount.space', value)}
                                        value={pageParams.amount.space}
                                    />
                                </div>

                                {/* Доступ к разделам */}
                                <div className="margin-top-sm">
                                    {/*<div className="margin-bottom-xp">{l('calc.sections')}:</div>*/}
                                    {sectionsRows.map((section_item) => {
                                        const selTar = tariffs.find(t => t.name === selectedTariff)
                                        const secPrice = deepGet(selTar, ['spec', 'sections', section_item.key], 0)
                                        // console.log('secPrice', section_item.key, selTar, deepGet(selTar, ['spec']))
                                        return <div key={section_item.key} className="checkbox-row">
                                            <span className="float-right">
                                                {secPrice} {t('common.money.symbol')}
                                            </span>
                                            <LabelSwitch
                                                className=""
                                                // size="small"
                                                name={section_item.key}
                                                onChange={onSectionChecked}
                                                checked={section_item.checked || inArray(section_item.key, checkedSections)}
                                                disabled={!isCustom || section_item.disabled}
                                                label={section_item.title}
                                            />
                                        </div>
                                    })}
                                </div>

                                <div className="margin-top-sm">
                                    {/*<InputNumber
                                        inputMode="numeric"
                                        pattern="[0-9]*"
                                        className="always-show-arrows align-center"
                                        bordered={true}
                                        min={0}
                                        step={1}
                                        placeholder="12"
                                        onChange={(value) => setState('amount.months', value)}
                                        style={{width: 63}}
                                        disabled={!checkedSections.length && !pageParams.amount.messages}
                                        value={pageParams.amount.months}
                                    />*/}

                                    <Text className="margin-right-sm">{l('calc.months.title')}</Text>
                                    <Radio.Group
                                        onChange={(e) => setState('amount.months', e.target.value)}
                                        value={pageParams.amount.months}
                                        disabled={!checkedSections.length && !pageParams.amount.messages}
                                        style={{
                                            width: '100%',
                                            marginTop: 10,
                                            marginLeft: 10,
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Radio value={1}>{l('calc.months.amount.1')}</Radio>
                                        <Radio value={6}>
                                            {selectedTariff === 'custom' ? l('calc.months.amount.6') : l('calc.months.amount.6-discount')}
                                        </Radio>
                                        <Radio value={12}>
                                            {selectedTariff === 'custom' ? l('calc.months.amount.12') : l('calc.months.amount.12-discount')}
                                        </Radio>
                                    </Radio.Group>
                                </div>

                                <hr className="margin-top-sm" style={{opacity: 0.5}}/>

                                {/* Стоимость выбранного тарифа | Оплатить тариф с баланса */}
                                <div className="margin-top-pm">
                                    <Row gutter={18} align="stretch">
                                        <Col xs={24} sm={13}>
                                            <div className="ant-typography ant-typography-secondary font-size-md"
                                                 style={{marginTop: 4}}>
                                                {l('calc.pay.price')}:
                                            </div>
                                        </Col>
                                        <Col xs={24} sm={11}>
                                            <Title level={3} className="inline" style={{fontWeight: 400}}>
                                                {roundPrice(tariffPrice)} {t('common.money.abbreviation')}
                                            </Title>
                                        </Col>
                                    </Row>

                                    <div>
                                        {renderApplyButton()}

                                        {/*{proj_balance ?
                                            // <Tooltip title={l('calc.pay.amount_desc')} placement="topLeft">
                                            <div className="inline margin-right-pm">
                                                <Text type="secondary">{l('calc.pay.amount')}: </Text>
                                                <Text
                                                    type="secondary"
                                                    className="nowrap"
                                                >
                                                    {tariffPrice > proj_balance ? roundPrice(tariffPrice - proj_balance) : 0} {t('common.money.abbreviation')}
                                                </Text>
                                            </div> : null
                                        }*/}
                                        {isEnough ? null :
                                            <div>
                                                <Text type="secondary">{l('calc.pay.not_enough')}: </Text>
                                                <Text type="danger">
                                                    {roundPrice(tariffPrice - proj_balance)} {t('common.money.abbreviation')}
                                                </Text>
                                                {/*<Text type={isEnough ? null : "danger"}>{tariffPrice} {t('common.money.symbol')}</Text>*/}
                                            </div>
                                        }
                                    </div>
                                </div>

                                <hr className="margin-top-md" style={{opacity: 0.5}}/>

                                {/* Кнопки оплаты */}
                                <Title className="margin-top-md" level={2}>{l('account.title')}</Title>

                                <div>
                                    {/*Баланс проекта*/}
                                    <div className="margin-top-sm">
                                        <div className="color-secondary font-size-md margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('account.project.title')}

                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('account.project.short_title')}
                                                <Tooltip title={l('account.project.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>

                                        <div className="float-right">
                                            <Button
                                                type="primary"
                                                loading={order.loading.balance}
                                                //disabled={pageParams.amount.order < 100}
                                                icon={<ShoppingOutlined/>}
                                                onClick={showOrderModal}
                                            >{t('common.action.add_funds')}</Button>
                                        </div>

                                        <Title level={4} className="inline color-secondary"
                                               style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}>
                                            {numberForHuman(roundPrice(proj_balance))} {t('common.money.abbreviation')}
                                        </Title>
                                    </div>

                                    {/*Баланс вашего аккаунта*/}
                                    {balance > 0 ? <div className="margin-top-sm">
                                        <div className="color-secondary font-size-md margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('account.balance.title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('account.balance.short_title')}
                                                <Tooltip title={l('account.balance.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>
                                        <div className="float-right">
                                            <Button
                                                className="btn-bordered"
                                                type="primary"
                                                // loading={order.loading.project}
                                                loading={order.loading.balance}
                                                //disabled={true}
                                                disabled={!balance}
                                                icon={<ShoppingOutlined/>}
                                                onClick={() => setState('modal.project', true)}
                                            >{t('common.action.transfer')}</Button>
                                        </div>
                                        <Title level={4} className="color-secondary"
                                               style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}>
                                            {numberForHuman(roundPrice(balance))} {t('common.money.abbreviation')}
                                        </Title>
                                    </div> : null}

                                    {/*Накоплено монет:*/}
                                    {/*<div className="margin-top-sm">
                                        <div className="color-secondary font-size-md float-left margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('calc.pay.coins')}
                                                <Tooltip title={l('account.coins.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('calc.pay.short_coins')}
                                                <Tooltip title={l('account.coins.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>
                                        <div className="float-right">
                                            <Button
                                                className="btn-bordered"
                                                type="default"
                                                icon={<QuestionCircleOutlined/>}
                                                onClick={() => setState('modal.promo', true)}
                                                title={t('common.action.get')}
                                                style={{width: 140}}
                                            >{t('common.action.get')}</Button>
                                        </div>
                                        <Title level={4} className="inline color-secondary"
                                               style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 60}}>
                                            {numberForHuman(coins)}
                                        </Title>
                                    </div>*/}

                                    {/* Add funds to project AI balance */}
                                    {/*projectTariffType !== 'free'*/}
                                    <div className="margin-top-sm">
                                        <div className="color-secondary font-size-md margin-right-ps">
                                            <span className="hidden-xs">
                                                {l('account.ai.title')}

                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                            <span className="visible-xs">
                                                {l('account.ai.short_title')}
                                                <Tooltip title={l('account.ai.desc')}>
                                                    <QuestionCircleOutlined
                                                        style={{marginLeft: 8, fontSize: 14}}
                                                    />
                                                </Tooltip>
                                            </span>
                                        </div>

                                        <div className="float-right">
                                            <Tooltip
                                                title={(balance + proj_balance) <= 0 ? l('calc.pay.not_enough_desc') : ''}
                                                placement="topRight"
                                            >
                                                <div className="inline">
                                                    <Button
                                                        className="btn-bordered"
                                                        type="default"
                                                        loading={order.loading.ai}
                                                        //disabled={true}
                                                        disabled={(balance + proj_balance) <= 0}
                                                        icon={<PlusOutlined/>}
                                                        onClick={() => setState('modal.ai', true)}
                                                    >{t('common.action.add_funds')}</Button>
                                                </div>
                                            </Tooltip>
                                        </div>

                                        <Title
                                            level={4}
                                            className="color-secondary"
                                            style={{fontWeight: 400, marginTop: 0, marginBottom: 0, minWidth: 85}}
                                        >
                                            {numberForHuman(roundPrice(ai_balance))} {t('common.money.abbreviation')}
                                        </Title>
                                    </div>
                                </div>

                                <div id="modals">
                                    {/* Add funds to project balance */}
                                    <InputModal
                                        initValue={''}
                                        isVisible={pageParams.modal.project}
                                        setVisible={(value) => {
                                            setState('modal.project', value)
                                        }}
                                        onModalOk={onProjectFormSubmit}
                                        title={l('account.project.modal.title')}
                                        placeholder={t('common.placeholder.amount')}
                                        loading={order.loading.project}
                                    />

                                    {/* Add funds to account balance */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.order}
                                        setVisible={(value) => {
                                            setState('modal.order', value)
                                        }}
                                        onOk={onOk}
                                        title={l('account.balance.modal.title')}
                                        loadingOnOk={order.loading.balance}
                                        disabled={pageParams.amount.order === 0} // pageParams.amount.order === 0 && tariffPrice === 0
                                    >
                                        <Form
                                            id="order-form"
                                            name="order-form"
                                            layout="vertical"
                                            form={orderForm}
                                            onValuesChange={onOrderFormChange}
                                            onFinish={onOrderFormSubmit}
                                        >
                                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                                <Form.Item
                                                    name="amount"
                                                    label={l('account.balance.modal.amount.label')}
                                                >
                                                    <InputNumber
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        className="always-show-arrows"
                                                        min={0}
                                                        step={1000}
                                                        placeholder="1000"
                                                        // onChange={(value) => setState('amount.order', value)}
                                                        // defaultValue={tariffPrice ? tariffPrice : null}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.balance.modal.phone.desc')}>
                                                <Form.Item name="phone" label={l('account.balance.modal.phone.label')}>
                                                    <Input
                                                        inputMode="numeric"
                                                        pattern="\+*[0-9]*"
                                                        placeholder={t('common.placeholder.text')}
                                                        // placeholder="+7 987 654 32 10"
                                                        // onChange={(event, val) => {
                                                        //     console.log('value', event, val)
                                                        //     setState('order.phone', event.target.value)
                                                        // }}
                                                        defaultValue={admin_phone}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper className="" desc={l('account.balance.modal.promo.desc')}>
                                                <Form.Item name="promo" label={l('account.balance.modal.promo.label')}>
                                                    <Input
                                                        showCount={true}
                                                        maxLength={30}
                                                        placeholder={t('common.placeholder.text')}
                                                        autoComplete='off'
                                                        // onChange={(event) => {
                                                        //     // console.log('balance.modal.promo', event.target.value)
                                                        //     setState('promo.code', event.target.value)
                                                        // }}
                                                        defaultValue={pageParams.promo.code}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            {/*<FormItemWrapper desc={l('account.balance.modal.to_project.desc')}>
                                                <Form.Item name="to_project">
                                                    <LabelSwitch
                                                        label={l('account.balance.modal.to_project.label')}
                                                        onChange={(value) => setState('order.to_project', value)}
                                                        checked={pageParams.order.to_project}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>*/}

                                            <FormItemWrapper desc={l('account.balance.modal.subscribe.desc')}>
                                                <Form.Item name="subscribe">
                                                    <LabelSwitch
                                                        label={l('account.balance.modal.subscribe.label')}
                                                        onChange={(value) => setState('order.subscribe', value)}
                                                        checked={pageParams.order.subscribe}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper
                                                desc={l('account.balance.modal.auto.desc')}
                                                className={pageParams.order.subscribe ? '' : 'hide'}
                                            >
                                                <Form.Item name="auto">
                                                    <LabelSwitch
                                                        label={l('account.balance.modal.auto.label')}
                                                        onChange={(value) => setState('order.auto', value)}
                                                        checked={pageParams.order.auto}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>
                                        </Form>
                                    </SimpleModal>

                                    {/* Get Coins */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.promo}
                                        setVisible={(value) => {
                                            setState('modal.promo', value)
                                        }}
                                        onOk={onPromoSave}
                                        actionLabel="save"
                                        title={l('account.promo.modal.title')}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: l('account.promo.modal.desc')}}/>
                                        <NoForm className="margin-top-sm">
                                            <FormItemWrapper className="" desc={l('account.promo.modal.field.desc')}>
                                                <FormItemLabel label={l('account.promo.modal.field.label')}/>
                                                <Input
                                                    showCount={true}
                                                    maxLength={30}
                                                    placeholder={t('common.placeholder.text')}
                                                    autoComplete='off'
                                                    defaultValue={pageParams.promo.name}
                                                    onBlur={(e) => {
                                                        setState('promo.name', e.target.value)
                                                    }}
                                                    onChange={(value) => {
                                                        setState('promo.name', value)
                                                    }}
                                                />
                                            </FormItemWrapper>
                                            <div className="float-right">{pageParams.promo.bonus}%</div>
                                            <Text>{l('account.promo.modal.slider.label')}</Text>
                                            <Slider
                                                className="primary"
                                                min={0}
                                                max={20}
                                                step={1}
                                                onChange={(value) => setState('promo.bonus', value)}
                                                value={pageParams.promo.bonus}
                                                defaultValue={pageParams.promo.bonus}
                                            />
                                            <div className="margin-top-sm">
                                                <div dangerouslySetInnerHTML={
                                                    {
                                                        __html: t('project.' + section + '.account.promo.modal.slider.desc', {
                                                            postProcess: 'sprintf',
                                                            sprintf: [
                                                                numberForHuman((20 - pageParams.promo.bonus) / 100 * 1000 * 10)
                                                            ]
                                                        })
                                                    }
                                                }/>
                                            </div>
                                        </NoForm>
                                    </SimpleModal>

                                    {/* Add funds to project AI balance */}
                                    <SimpleModal
                                        isVisible={pageParams.modal.ai}
                                        setVisible={(value) => {
                                            setState('modal.ai', value)
                                        }}
                                        onOk={onAiOk}
                                        title={l('account.ai.modal.title')}
                                        loadingOnOk={order.loading.ai}
                                        disabled={pageParams.ai.amount <= 0 || (
                                            pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > proj_balance ||
                                            !pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > balance
                                        )}
                                    >
                                        <Form
                                            id="ai-balance-form"
                                            name="ai-balance-form"
                                            layout="vertical"
                                            form={aiForm}
                                            onValuesChange={onAiFormChange}
                                            onFinish={onAiFormSubmit}
                                        >
                                            <FormItemWrapper className="" desc={l('account.balance.modal.amount.desc')}>
                                                <Form.Item
                                                    name="amount"
                                                    label={l('account.balance.modal.amount.label')}
                                                >
                                                    <InputNumber
                                                        inputMode="numeric"
                                                        pattern="[0-9]*"
                                                        className="always-show-arrows"
                                                        min={0}
                                                        step={1000}
                                                        placeholder="1000"
                                                        // onChange={(value) => setState('amount.order', value)}
                                                        // defaultValue={tariffPrice ? tariffPrice : null}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>

                                            <FormItemWrapper desc={l('account.ai.modal.from_project.desc')}>
                                                <Form.Item name="from_project">
                                                    <LabelSwitch
                                                        label={l('account.ai.modal.from_project.label')}
                                                        onChange={(value) => setState('ai.from_project', value)}
                                                        checked={pageParams.ai.from_project}
                                                    />
                                                </Form.Item>
                                            </FormItemWrapper>
                                        </Form>

                                        {pageParams.ai.amount > 0 && (
                                            pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > proj_balance ||
                                            !pageParams.ai.from_project && pageParams.ai.amount / dotenv.ai_coin_factor > balance
                                        ) ? <p
                                            className="color-danger"
                                        >
                                            {l('account.ai.modal.not_enough')}
                                        </p> : null}

                                    </SimpleModal>
                                </div>
                            </ContentCard>
                        </Col>
                    </Row>
                </Content>

                <div className="margin-top-pl"/>
            </Layout>
        </AppWrapper>
    )
}

export default Tariff