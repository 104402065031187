import React, {useEffect, useLayoutEffect, useState} from 'react'
import classes from "./UserProfile.module.scss"
import {Button, Dropdown, Layout, Menu, Tooltip, Typography} from "antd";
import {Collapse} from "react-collapse/lib/Collapse";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {dotenv, routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {dispatchItemData} from "../../library/containers";
import {useTranslation} from "react-i18next";
import {
    ArrowLeftOutlined,
    CloseOutlined,
    CommentOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    ExperimentOutlined,
    LikeOutlined,
    MailOutlined,
    NotificationOutlined,
    RightOutlined,
    RobotOutlined,
    SettingOutlined,
    TeamOutlined,
    UserDeleteOutlined,
    UserOutlined,
} from "@ant-design/icons";
import {AiOutlinePlus} from "react-icons/ai";
import {l} from "../../library/locale";
import {copyToClipboard} from "../../library/clipboard";
import {FolderListSimple} from "../../components/List/Table/FolderListSimple";
import {
    assocArrayByField,
    deepGet,
    getUtcDateTime,
    inArray,
    momentFromUnix,
    objectLength,
    objectToQueryString,
    redirect,
    ucFirst
} from "../../library/functions";
import {ListHeader} from "../../components/List/Header/ListHeader";
import {setUserStoreParam} from "../../redux/reducers/UserReducer";
import Preloader from "../System/Preloader";
import Fa from "../../components/Icon/Fa";
import ListTableRowMenu from "../../components/List/Table/ListTableRowMenu";
import {renderStatusesMenu} from "../Sales/Order/OrderListTable";
import {createObjectFromIntegrations} from "../Integrations/IntegrationEdit";
import {NavigateLink} from "../../components/Navigate/Navigate";

const {Content} = Layout;
const {Text} = Typography;

const fieldTypesMap = {
    'float': 'input-numeric',
    'text': 'input-text',
    'json': 'brackets-curly',
    'array': 'brackets-square',
    'datetime': 'alarm-clock',
}

function UserOutlinedOutlined() {
    return null;
}

const UserProfile = () => {
    const {t} = useTranslation()
    const section = 'user'
    const Section = ucFirst(section)
    const adminSection = section
    const yesterday = getUtcDateTime() - 86400

    // data from URL params
    const params = useParams()
    const id = Number(params.id)

    // data from GET params
    const [filters, setFilters] = useState({})
    const [openedUtms, setOpenedUtms] = useState([])

    // init hooks
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // sync with store
    const {
        admin,
        project,
        user,
        contact,
        access,
        order,
        field,
        fieldValue,
        userUtm,
        integration
    } = useSelector(store => store)
    const item = user.item
    const folders = user.folders
    const contactItems = contact.list
    const accessItems = access.list
    const utmItems = userUtm.list
    const project_item = project.item
    const isMainProject = project_item.id === dotenv.main_project
    const fieldsMap = assocArrayByField(field.list, 'id')
    let fieldValuesList = fieldValue.list
    if (!fieldValuesList) fieldValuesList = []
    const integration_items = {0: '—', ...createObjectFromIntegrations(t, integration.list, false)};

    let fieldValuesMap = {
        'list': [],
        'field': [],
        'currency': [],
        // 'money': [],
        'achievement': [],
        'resource': [],
    };

    for (const fieldValueItem of fieldValuesList) {
        if (!fieldValueItem.field_id) continue;
        const fieldItem = fieldsMap[fieldValueItem.field_id];
        if (!fieldItem) continue; // do not show deleted fields values

        const fieldType = inArray(fieldItem.system_field_type, ['form', 'crm']) ? 'field' : fieldItem.system_field_type;
        if (!fieldsMap[fieldType]) fieldsMap[fieldType] = [] // if I will forget about it when new section created
        fieldValuesMap[fieldType].push({...fieldValueItem, field: fieldItem})
    }

    let full_name = ''
    if (item.first_name || item.last_name) full_name = item.first_name + ' ' + item.last_name
    else if (item.title) full_name = item.title
    else if (!full_name) full_name = t('user.object.item') + ' #' + item.id

    // get data from API first
    useLayoutEffect(() => {
        dispatchItemData(dispatch, admin, project_item, section, id, filters)
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, id, filters])

    useEffect(() => {
        if (admin.authorized && project_item.id && item.id && item.id === id) {
            // get integration list for contacts
            if (admin.authorized && project_item.id && !integration.list.length) {
                dispatch({type: 'getIntegrationList', admin, filters: {project_id: project_item.id}});
            }

            // get project fields list
            if (field.status !== 'ready') {
                dispatch({type: 'getFieldList', admin, filters: {project_id: project_item.id, deleted_since: 0}})
            }

            // get user field values list
            dispatch({
                type: 'getFieldValueList', admin, filters: {
                    project_id: project_item.id,
                    user_id: item.id,
                    ...filters
                }
            })

            dispatch({
                type: 'getAccessList', admin, filters: {
                    project_id: project_item.id,
                    user_id: item.id,
                    ordering: '-id',
                    ...filters
                }
            })

            dispatch({
                type: 'getUserUtmList', admin, filters: {
                    project_id: project_item.id,
                    user_id: item.id,
                    ordering: '-id',
                    ...filters
                }
            })

            if (isMainProject) dispatch({
                type: 'getUserProjectList', admin, filters: {
                    user_id: item.id,
                    ...filters
                }
            })
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, item, filters])

    useEffect(() => {
        if (admin.authorized && project_item.id && item.id && item.id === id) {
            dispatch({
                type: 'getOrderList', admin, filters: {
                    project_id: project_item.id,
                    user_ids: [item.id],
                    page_size: 0,
                    ...filters
                }
            })
        }
        //eslint-disable-next-line
    }, [admin.authorized, project_item.id, item, filters, order.timestamp])

    // render preloader on data loading
    if ((item.id && item.id !== id) || (fieldValuesList && fieldValuesList.length > 0 && fieldValuesList[0].user_id !== id)) {
        // console.log('Preloader', item.id, id, fieldValuesList, (item.id && item.id !== id), (fieldValuesList && fieldValuesList.length > 0 && fieldValuesList[0].user_id !== id))
        return <Preloader/>
    }

    const recordUrl = (id, action) => `${routes.project_list}/${project_item.id}/${section}/${action}/${id}`
    const recordEdit = () => navigate(recordUrl(id, 'edit'))
    const backToList = () => {
        let suffix = ''
        if (objectLength(user.filters) > 0) suffix = '?' + objectToQueryString(user.filters)
        navigate(`${routes.project_list}/${project_item.id}/${adminSection}${suffix}`);
    }

    const recordItem = (record_id, sect, action, query = '', linkOnly = false) => {
        let link = `${routes.project_list}/${project_item.id}/${sect}/${action}/${record_id}`
        if (query) link += '?' + query

        if (linkOnly) return link
        else navigate(link)
    }

    const dialogLink = (record_id) => {
        return `${routes.project_list}/${project_item.id}${routes.local.dialog_list}/${record_id}?user_id=${id}`
    }

    const recordFieldUrl = (record_id, sect) => recordUrl(id, 'edit') + `/${sect}/${record_id}`
    const recordFieldEdit = (record_id, sect) => navigate(recordFieldUrl(record_id, sect))
    const recordFieldCreate = (sect = null, type = null) => {
        let url = recordFieldUrl(0, 'field');
        let params = {}
        if (sect) params['section'] = sect;
        if (type) params['type'] = type;
        if (objectLength(params) > 0) url += '?' + objectToQueryString(params);
        navigate(url);
    }

    const recordCopy = (id, sect, updateList = false) => dispatch({type: 'copy' + ucFirst(sect), admin, id, updateList})
    const recordDelete = (id, sect, updateList = false) => dispatch({
        type: 'delete' + ucFirst(sect),
        admin,
        id,
        updateList
    })
    const recordRestore = (id, sect, updateList = false) => {
        const params = {id: id, project_id: project_item.id, user_id: item.id}
        dispatch({type: 'restore' + ucFirst(sect), admin, data: params, updateList})
    }

    const recordChangeStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project_item.id,
            ignore: !status,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const recordChangeOrderStatus = (id, status) => {
        const data = {
            id: id,
            project_id: project.id,
            status: status,
        }
        dispatch({type: 'updateOrder', admin, data})
    }

    const recordFieldDelete = (id) => dispatch({
        type: 'deleteFieldValue', admin, filters: {
            project_id: project_item.id,
            user_id: item.id,
            field_id: id,
        }
    })

    const recordFieldRestore = (id) => {
        dispatch({
            type: 'restoreFieldValue', admin, data: {
                project_id: project_item.id,
                user_id: item.id,
                field_id: id,
            }
        })
    }

    const renderObjectTableLink = (record, text, sect, textType = null) => {
        return <a
            className="full-width"
            href={recordFieldUrl(record.id, sect)}
            onClick={(e) => {
                e.preventDefault()
            }}
        >{text}</a>
    }

    const renderValuePrefix = (fieldType, field) => {
        if (fieldType === 'field') return t('field.form.data_type_admin.value.' + field.type)
        else if (fieldType === 'list') return t('user.object.field.level')
        else if (field.type === 'int') return t('user.object.field.count')
        else return t('user.object.field.value')
    }

    const renderValue = (value, limit = 17) => {
        if (value.length > limit) return value.substring(0, limit) + '...'
        return value
    }

    const getStringValue = (value) => {
        let result = value || ''
        if (typeof value === 'object') result = JSON.stringify(value)
        else if (typeof value === 'number') result = value.toString()
        return result
    }

    const renderContactLink = (record) => {
        const type = record.type;
        // remove spaces from values
        let record_value = record.value ? record.value.replace(/\s/g, '') : ''
        let public_name = record.public_name ? record.public_name.replace(/\s/g, '') : ''

        if (type === 'email') {
            return <a href={`mailto:${record_value}`}>
                <Text type="secondary">{t('common.action.write')}</Text>
            </a>
        } else if (type === 'phone') {
            return <a href={`tel:${record_value}`}>
                <Text type="secondary">{t('common.action.call')}</Text>
            </a>
        } else if (type === 'tg_id' && public_name) {
            // console.log('record.public_name', record.public_name)
            return <a href={`https://t.me/${public_name}`} rel="noreferrer noopener" target="_blank">
                <Text type="secondary">@{renderValue(public_name)}</Text>
            </a>
        } else if (type === 'ig_id' && public_name) {
            // console.log('record.public_name', record.public_name)
            return <a href={`https://www.instagram.com/${public_name}`} rel="noreferrer noopener" target="_blank">
                <Text type="secondary">@{renderValue(public_name)}</Text>
            </a>
        } else if (type === 'vk_id' && record_value) {
            record_value = parseInt(record_value);
            if (record_value > 2000000000) return null; // exclude chats
            const userLink = record_value > 0 ? 'id' + record_value : 'club' + Math.abs(record_value)
            if (!public_name || public_name === '') public_name = userLink;

            return <a href={`https://vk.com/${userLink}`} rel="noreferrer noopener" target="_blank">
                <Text type="secondary">@{renderValue(public_name)}</Text>
            </a>
        }
    }

    const renderUserIcon = (type) => {
        let icon = null
        if (type === 'user') {
            icon = <UserOutlined/>
        } else if (type === 'chat') {
            icon = <CommentOutlined/>
        } else if (type === 'bot') {
            icon = <RobotOutlined/>
        } else if (type === 'channel') {
            icon = <NotificationOutlined/>
        }
        return <Tooltip title={t(section + '.form.profile_type.value.' + type)}>
            <span className="user-profile-icon">{icon}</span>
        </Tooltip>
    }

    const onFolderChange = (folder_id, status) => {
        let foldersStatuses = [...folders]
        if (status) foldersStatuses.push(folder_id)
        else foldersStatuses = foldersStatuses.filter((id) => id !== folder_id)
        dispatch(setUserStoreParam({folders: foldersStatuses}))
    }

    const toggleUtm = (id) => {
        setOpenedUtms(openedUtms.includes(id) ? openedUtms.filter((item) => item !== id) : [...openedUtms, id])
    }

    const connectUserToAdmin = () => {
        dispatch({
            type: 'connectAdminToLocalUser', admin, data: {
                user_id: item.id,
                project_id: project_item.id,
            }
        })
    }

    const disconnectUser = () => {
        dispatch({
            type: 'connectAdminToLocalUser', admin, data: {
                user_id: 0,
                project_id: project_item.id,
            }
        })
    }

    const onMenuClick = (e) => {
        if (e.key === 'admin') {
            navigate(routes.admin.core + '/edit/' + item.id)
        }
    };

    let primaryMenu = null;
    if (isMainProject) {
        primaryMenu = (<Menu
                onClick={onMenuClick}
                items={[
                    {
                        key: 'admin',
                        label: t('common.button.admin'),
                        icon: <UserOutlined/>,
                    },
                ]}
            />
        )
    }

    // general render
    let connectedUserId = deepGet(admin, 'params.connection.' + project_item.id + '.user', 0);
    // console.log('connectedUserId', connectedUserId, admin.params.connection)
    return (
        <AppWrapper>
            <Layout className="site-layout site-layout-background user-profile">
                <ListHeader
                    title={<>{renderUserIcon(item.profile_type)} {full_name}</>}
                    section={section}
                    filters={filters ?? {}}
                    setFilters={setFilters}
                    filterFields={[[{name: 'is_deleted', type: 'switcher'}]]}
                    onSearch={null}
                    searchPlaceholder={t('page_header.search.name')}
                    onPrimary={recordEdit}
                    primaryLabel={l('common.action.change')}
                    primaryIcon={<EditOutlined/>}
                    primaryMenu={primaryMenu}
                />

                <Content className="page-container site-layout-background padding-top-none users-list">
                    <div className="margin-bottom-ps"/>
                    {/* Contacts */}
                    <FolderListSimple
                        key={'profile-section-contacts'}
                        admin={admin}
                        section={section}
                        sectionSaga={'Contact'}
                        project={project_item}
                        filters={filters}
                        items={contactItems.map((record) => {
                            return {
                                // ...record,
                                key: record.id,
                                id: record.id,
                                type: record.type,
                                active: record.is_allowed && (!record.last_message_at || record.last_message_at >= yesterday),
                                value: record.value,
                                variable: '{contact:' + record.type + '}',
                                public_name: record.public_name ?? '',
                                integration: integration_items[record.bot_id ?? 0],
                                has_bot: record.bot_id > 0,
                            }
                        })}
                        columns={[
                            {
                                dataIndex: 'link',
                                className: 'cursor-pointer',
                                responsive: ['xs'],
                                render: (icon, record) => {
                                    return renderObjectTableLink(
                                        record,
                                        <>
                                            <Text type={!record.active ? 'danger' : null}>
                                                {t(section + '.contact.form.type.value.' + record.type)}
                                            </Text>
                                            <Text type="secondary">
                                                {(record.integration !== '—') ? ' - ' + record.integration : ''}
                                            </Text>
                                            <br/>
                                            <Text type="secondary">{renderValue(record.value, 27)}</Text>
                                        </>,
                                        'contact'
                                    )
                                },
                                onCell: (record) => {
                                    return {
                                        onClick: (ev) => recordFieldEdit(record.id, 'contact')
                                    };
                                },
                            },
                            {
                                dataIndex: 'type',
                                className: 'cursor-pointer',
                                responsive: ['sm'],
                                width: '40%',
                                render: (icon, record) => {
                                    return renderObjectTableLink(
                                        record,
                                        <Text type={!record.active ? 'danger' : null}>
                                            {t(section + '.contact.form.type.value.' + record.type)}
                                        </Text>,
                                        'contact'
                                    )
                                },
                                onCell: (record) => {
                                    return {
                                        onClick: (ev) => recordFieldEdit(record.id, 'contact')
                                    };
                                },
                            },
                            {
                                dataIndex: 'value',
                                className: 'cursor-pointer',
                                responsive: ['sm'],
                                // width: 70,
                                render: (value, record) => {
                                    return <Text type="secondary">{renderValue(value, 30)}</Text>
                                },
                                onCell: (record, rowIndex) => {
                                    return {
                                        onClick: (ev) => copyToClipboard(record.value),
                                        onContextMenu: (ev) => {
                                            ev.preventDefault()
                                            copyToClipboard(record.variable)
                                        },
                                    };
                                },
                            },
                            {
                                dataIndex: 'public_name',
                                className: 'cursor-pointer',
                                responsive: ['sm'],
                                // width: 100,
                                render: (value, record) => {
                                    return renderContactLink(record)
                                },
                            },
                            {
                                dataIndex: 'integration',
                                responsive: ['xl'],
                                // className: 'cursor-pointer',
                                // width: 70,
                                render: (value, record) => {
                                    return <Text type="secondary">{value}</Text>
                                },
                            },
                            {
                                // Действия
                                key: 'actions',
                                className: 'align-right',
                                width: 85,
                                render: (text, record) => (
                                    <div className="table-row-buttons">
                                        {record.has_bot && inArray(record.type, dotenv.messanger_contact_types) ?
                                            <Tooltip title={l('table.icon.write')}>
                                                <Button
                                                    type="text"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        navigate(dialogLink(record.id))
                                                    }}
                                                    size="large"
                                                    icon={<MailOutlined/>}
                                                    href={dialogLink(record.id)}
                                                />
                                            </Tooltip>
                                            : null}
                                        <Tooltip title={l('table.icon.settings')}>
                                            <Button
                                                type="text"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    recordFieldEdit(record.id, 'contact')
                                                }}
                                                size="large"
                                                icon={<SettingOutlined/>}
                                                href={recordFieldUrl(record.id, 'contact')}
                                            />
                                        </Tooltip>
                                        {filters.is_deleted ?
                                            <Tooltip title={l('table.icon.restore')}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() => recordRestore(record.id, 'contact')}
                                                    icon={<ExperimentOutlined/>}
                                                />
                                            </Tooltip> :
                                            <Tooltip title={l('table.icon.delete')}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() => recordDelete(record.id, 'contact')}
                                                    icon={<DeleteOutlined/>}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                ),
                            },
                        ]}
                        onFolderChange={onFolderChange}
                        folder={{
                            id: 'contact',
                            title: t('user.object.section.contact') + ': ' + contactItems.length,
                            type: 'folder.section', // for locale
                            opened: !folders.length || inArray('contact', folders),
                        }}
                        recordCreate={() => recordFieldEdit(0, 'contact')}
                    />

                    {/* Orders */}
                    <FolderListSimple
                        key={'profile-section-orders'}
                        admin={admin}
                        section={section}
                        sectionSaga={'Order'}
                        project={project_item}
                        filters={filters}
                        items={order.list.map((record) => {
                            return {
                                key: record.id,
                                id: record.id,
                                title: record.title,
                                status: record.status,
                                price: record.price,
                                amount: record.amount,
                                created_at: record.created_at,
                            }
                        })}
                        columns={[
                            {
                                dataIndex: 'id',
                                className: 'cursor-pointer text-secondary align-center',
                                title: t('common.table.header.id'),
                                width: 50,
                                responsive: ['sm'],
                                onCell: (record, rowIndex) => {
                                    return {
                                        onClick: (ev) => copyToClipboard(record.id, l('common.result.copied.neuter'))
                                    };
                                },
                            },

                            {
                                dataIndex: 'key',
                                className: '',
                                title: 'all',
                                responsive: ['xs'],
                                render: (value, record) => (
                                    <>
                                        <div className="cursor-pointer"
                                             onClick={() => recordItem(record.id, 'order', 'edit', 'return_to=user/profile/' + item.id)}>
                                            {record.title}
                                        </div>
                                        <div>
                                            <span className="inline cursor-pointer color-secondary margin-right-sm">
                                                {renderStatusesMenu(record, 'order', order.statuses, recordChangeOrderStatus)}
                                            </span>
                                            <span className="inline color-secondary">
                                                {record.amount} / {record.price} {t('common.money.symbol')}
                                            </span>
                                        </div>
                                    </>
                                ),
                            },
                            {
                                dataIndex: 'title',
                                className: 'cursor-pointer',
                                responsive: ['sm'],
                                title: t('common.table.header.label'),
                                onCell: (record, rowIndex) => {
                                    return {
                                        onClick: (ev) => recordItem(record.id, 'order', 'edit', 'return_to=user/profile/' + item.id)
                                    };
                                },
                            },
                            {
                                dataIndex: 'price',
                                className: 'cursor-pointer text-secondary',
                                title: t('common.table.header.price'),
                                width: 190,
                                responsive: ['xl'],
                                render: (value, record) => (
                                    <span
                                        className="inline">{record.amount} / {record.price} {t('common.money.symbol')}</span>
                                ),
                                onCell: (record) => {
                                    return {
                                        onClick: (ev) => recordItem(record.id, 'order', 'edit', 'return_to=user/profile/' + item.id)
                                    };
                                },
                            },
                            {
                                dataIndex: 'created_at',
                                className: 'cursor-pointer',
                                responsive: ['md'],
                                title: t('common.table.header.label'),
                                render: (text, record) => (<span className="text-secondary">
                                    {momentFromUnix(text, 'DD.MM.YYYY')}
                                </span>),
                                onCell: (record, rowIndex) => {
                                    return {
                                        onClick: (ev) => recordItem(record.id, 'order', 'edit', 'return_to=user/profile/' + item.id)
                                    };
                                },
                            },
                            {
                                dataIndex: 'status',
                                className: 'cursor-pointer',
                                title: t('common.form.status'),
                                width: 130,
                                responsive: ['sm'],
                                render: (value, record) => {
                                    return renderStatusesMenu(record, 'order', order.statuses, recordChangeOrderStatus)
                                },
                            },
                            {
                                key: 'menu',
                                className: 'menu-column',
                                width: 55,
                                render: (text, record) => (
                                    <ListTableRowMenu items={[
                                        {
                                            key: 'orderMenuEdit',
                                            label: l('table.menu.edit'),
                                            action: (record) => recordItem(record.id, 'order', 'edit', 'return_to=user/profile/' + item.id)
                                        },
                                        {
                                            key: 'orderMenuCopy',
                                            label: l('table.menu.duplicate'),
                                            action: (record) => {
                                                recordCopy(record.id, 'order')
                                            }
                                        },
                                        filters.is_deleted ? {
                                            key: 'orderMenuRestore',
                                            label: l('table.menu.restore'),
                                            action: (record) => {
                                                recordRestore(record.id, 'order')

                                            }
                                        } : {
                                            key: 'orderMenuDelete',
                                            label: l('table.menu.delete'),
                                            action: (record) => {
                                                recordDelete(record.id, 'order')
                                            }
                                        },
                                    ]} record={record}/>
                                ),
                            },
                        ]}
                        onFolderChange={onFolderChange}
                        folder={{
                            id: 'order',
                            title: <>
                                {t('user.object.section.order')}: {order.count} <span
                                className="hidden-xs float-right margin-right-xp font-size-lg"
                                style={{fontWeight: 400}}
                            >
                                    <Tooltip title={t('user.object.section.order_sum')}>
                                        {order.amount} / {order.price} {t('common.money.abbreviation')}
                                    </Tooltip>
                                </span>
                            </>,
                            type: 'folder.section', // for locale
                            opened: inArray('order', folders),
                        }}
                        recordCreate={() => recordItem(0, 'order', 'edit', 'user_id=' + item.id + '&return_to=user/profile/' + item.id)}
                    />

                    {/* Page Access */}
                    <FolderListSimple
                        key={'profile-section-pages'}
                        admin={admin}
                        section={section}
                        sectionSaga={'Access'}
                        project={project_item}
                        filters={filters}
                        items={accessItems.map((record) => {
                            return {
                                // ...record,
                                key: record.id,
                                id: record.id,
                                title: record.page && record.page.title,
                                value: record.value,
                            }
                        })}
                        columns={[
                            {
                                dataIndex: 'title',
                                className: 'cursor-pointer',
                                onCell: (record) => {
                                    return {
                                        onClick: (ev) => recordFieldEdit(record.id, 'page')
                                    };
                                },
                            },
                            // {
                            //     dataIndex: 'value',
                            //     className: 'cursor-pointer',
                            //     responsive: ['sm'],
                            //     width: 70,
                            //     render: (value, record) => {
                            //         return <Text type="secondary">{value}</Text>
                            //     },
                            //     onCell: (record, rowIndex) => {
                            //         return {
                            //             onClick: (ev) => copyToClipboard(record.value),
                            //         };
                            //     },
                            // },
                            {
                                key: 'actions',
                                className: 'align-right',
                                width: 85,
                                render: (text, record) => (
                                    <div className="table-row-buttons">
                                        <Tooltip title={l('table.icon.settings')}>
                                            <Button
                                                type="text"
                                                onClick={(e) => {
                                                    e.preventDefault()
                                                    recordFieldEdit(record.id, 'page')
                                                }}
                                                size="large"
                                                icon={<SettingOutlined/>}
                                                href={recordFieldUrl(record.id, 'page')}
                                            />
                                        </Tooltip>
                                        {filters.is_deleted ?
                                            <Tooltip title={l('table.icon.restore')}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() => recordRestore(record.id, 'access')}
                                                    icon={<ExperimentOutlined/>}
                                                />
                                            </Tooltip> :
                                            <Tooltip title={l('table.icon.delete')}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() => recordDelete(record.id, 'access')}
                                                    icon={<DeleteOutlined/>}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                ),
                            },
                        ]}
                        onFolderChange={onFolderChange}
                        folder={{
                            id: 'page',
                            title: t('user.object.section.page') + ': ' + accessItems.length,
                            type: 'folder.section', // for locale
                            opened: inArray('page', folders),
                        }}
                        recordCreate={() => recordFieldEdit(0, 'page')}
                    />

                    {Object.entries(fieldValuesMap).map(([fieldType, fieldsList]) => {
                        // if (fieldType === 'field') return null;
                        // console.log('key, value', fieldType, fieldsList)

                        return <FolderListSimple
                            key={`profile-section-${fieldType}`}
                            admin={admin}
                            section={section}
                            sectionSaga={'UserField'}
                            project={project_item}
                            filters={filters}
                            onFolderChange={onFolderChange}
                            onDelete={recordFieldDelete}
                            onRestore={recordFieldRestore}
                            folder={{
                                id: fieldType,
                                title: t(`user.object.section.${fieldType}`) + ': ' + fieldsList.length,
                                type: 'folder.section', // for buttons labels (locales)
                                opened: inArray(fieldType, folders),
                            }}

                            recordCreate={fieldType === 'field' ? null : () => recordFieldCreate(fieldType)}
                            createRecordComponent={fieldType === 'field' ?
                                <Tooltip title={t('folder.section.add_item')} placement="topRight">
                                    <Dropdown menu={{
                                        items: Object.keys(fieldTypesMap).map((type) => {
                                            return {
                                                key: type,
                                                label: <div onClick={() => recordFieldCreate(fieldType, type)}>
                                                    <Text
                                                        type="secondary"
                                                        className="margin-right-xs"
                                                        style={{width: 20}}
                                                    >
                                                        <Fa icon={fieldTypesMap[type]}/>
                                                    </Text>
                                                    <Text>{t('field.form.data_type_admin.value.' + type)}</Text>
                                                </div>
                                            }
                                        })
                                    }} trigger={['click']}>
                                        <Button type="text">
                                            <AiOutlinePlus/>
                                        </Button>
                                    </Dropdown>
                                </Tooltip> : null}

                            items={fieldsList.map((record) => {
                                // console.log('record.field_id', record.field_id)
                                return {
                                    key: record.field_id + '-' + record.id,
                                    id: record.field_id,
                                    type: record.field.data_type_admin,
                                    title: record.field.title ?? record.field.name,
                                    value: getStringValue(record.value),
                                    variable: '{field:' + record.field.name + '}',
                                    updated_at: record.updated_at ?? record.created_at,
                                }
                            })}
                            columns={[
                                {
                                    dataIndex: 'link',
                                    className: 'cursor-pointer',
                                    responsive: ['xs'],
                                    render: (value, record) => {
                                        return renderObjectTableLink(
                                            record,
                                            <>
                                                <Text>{record.title}</Text><br/>
                                                <Text type="secondary" className="nowrap">
                                                    {renderValuePrefix(fieldType, record)}: {renderValue(record.value)}
                                                </Text>
                                            </>,
                                            'field'
                                        )
                                    },
                                    onCell: (record) => {
                                        return {
                                            onClick: (ev) => recordFieldEdit(record.id, 'field')
                                        };
                                    },
                                },
                                {
                                    dataIndex: 'title',
                                    className: 'cursor-pointer',
                                    responsive: ['sm'],
                                    width: '40%',
                                    render: (value, record) => {
                                        return renderObjectTableLink(
                                            record,
                                            <Text>{record.title}</Text>,
                                            'field'
                                        )
                                    },
                                    onCell: (record) => {
                                        return {
                                            onClick: (ev) => recordFieldEdit(record.id, 'field')
                                        };
                                    },
                                },
                                fieldType === 'field' ? {
                                    dataIndex: 'type',
                                    className: 'nowrap',
                                    responsive: ['md'],
                                    render: (value, record) => {
                                        return <Text type="secondary">
                                            {renderValuePrefix(fieldType, record)}:
                                        </Text>
                                    },
                                } : {
                                    dataIndex: 'value',
                                    className: 'cursor-pointer nowrap',
                                    responsive: ['sm'],
                                    // width: 70,
                                    render: (value, record) => {
                                        return <Text type="secondary">
                                            {renderValuePrefix(fieldType, record)}: {renderValue(value)}
                                        </Text>
                                    },
                                    onCell: (record, rowIndex) => {
                                        return {
                                            onClick: (ev) => copyToClipboard(record.value),
                                            onContextMenu: (ev) => {
                                                ev.preventDefault()
                                                copyToClipboard(record.variable)
                                            },
                                        };
                                    },
                                },
                                fieldType === 'field' ? {
                                    dataIndex: 'value',
                                    className: 'cursor-pointer nowrap',
                                    responsive: ['sm'],
                                    // width: 70,
                                    render: (value, record) => {
                                        return <Text type="secondary">{renderValue(value)}</Text>
                                    },
                                    onCell: (record, rowIndex) => {
                                        return {
                                            onClick: (ev) => copyToClipboard(record.value),
                                            onContextMenu: (ev) => {
                                                ev.preventDefault()
                                                copyToClipboard(record.variable)
                                            },
                                        };
                                    },
                                } : {
                                    dataIndex: 'updated_at',
                                    className: 'align-center nowrap',
                                    responsive: ['md'],
                                    render: (value, record) => {
                                        return <Text type="secondary">
                                            {momentFromUnix(record.updated_at, 'DD MMMM HH:mm')}
                                        </Text>
                                    },
                                },
                                {
                                    key: 'actions',
                                    className: 'align-right',
                                    width: 85,
                                    render: (text, record) => (
                                        <div className="table-row-buttons">
                                            <Tooltip title={t('table.icon.edit')} placement="topRight">
                                                <Button
                                                    type="text"
                                                    onClick={(e) => {
                                                        e.preventDefault()
                                                        recordFieldEdit(record.id, 'field')
                                                    }}
                                                    size="large"
                                                    icon={<SettingOutlined/>}
                                                    href={recordFieldUrl(record.id, 'field')}
                                                />
                                            </Tooltip>
                                            {filters.is_deleted ?
                                                <Tooltip title={l('table.icon.restore')}>
                                                    <Button
                                                        type="text"
                                                        size="large"
                                                        onClick={() => recordFieldRestore(record.id)}
                                                        icon={<ExperimentOutlined/>}
                                                    />
                                                </Tooltip> :
                                                <Tooltip title={l('table.icon.delete')}>
                                                    <Button
                                                        type="text"
                                                        size="large"
                                                        onClick={() => recordFieldDelete(record.id)}
                                                        icon={<DeleteOutlined/>}
                                                    />
                                                </Tooltip>
                                            }
                                        </div>
                                    ),
                                },
                            ]}
                        />
                    })}

                    {/* UTM tags */}
                    <FolderListSimple
                        key={'profile-section-utms'}
                        className="table-align-top"
                        admin={admin}
                        section={section}
                        sectionSaga={'UserUtm'}
                        project={project_item}
                        filters={filters}
                        items={utmItems.map((record) => {
                            return {
                                // ...record,
                                key: record.id,
                                id: record.id,
                                title: record.item && record.item.title,
                                created_at: record.created_at,
                                item: record.item,
                                page: record.page,
                                field: record.field,
                            }
                        })}
                        columns={[
                            {
                                dataIndex: 'title',
                                render: (value, record) => {
                                    if (!record.item) {
                                        console.log('record.item is empty', record.item)
                                        return null;
                                    }
                                    
                                    const isOpened = inArray(record.id, openedUtms);
                                    return (<div>
                                        <div
                                            onClick={() => toggleUtm(record.id)}
                                            className={record.item.deleted_at === 0 ? "cursor-pointer" : "cursor-pointer color-danger"}
                                            title={record.item.deleted_at === 0 ? '' : t('common.status.neuter.deleted')}
                                        >
                                            {isOpened ? <DownOutlined
                                                className="font-size-xs color-secondary margin-right-xs"
                                            /> : <RightOutlined
                                                className="font-size-xs color-secondary margin-right-xs"
                                            />} {value}
                                        </div>
                                        {record.item ? <Collapse isOpened={isOpened}>
                                            <ul className="list-style-secondary">
                                                <li><span
                                                    className={`${classes.param} inline`}>Source</span> {record.item.source}
                                                </li>
                                                <li><span
                                                    className={`${classes.param} color-secondary inline`}>Medium</span> {record.item.medium}
                                                </li>
                                                <li><span
                                                    className={`${classes.param} color-secondary inline`}>Campaign</span> {record.item.campaign}
                                                </li>
                                                <li><span
                                                    className={`${classes.param} color-secondary inline`}>Content</span> {record.item.content}
                                                </li>
                                                <li><span
                                                    className={`${classes.param} color-secondary inline`}>Term</span> {record.item.term}
                                                </li>
                                                <li><span
                                                    className={`${classes.param} color-secondary inline`}>Group</span> {record.item.group}
                                                    {record.page && record.field ? <><br/><br/></> : null}
                                                </li>

                                                {record.page && <li><span
                                                    className={`${classes.param} color-secondary inline`}>{t('page.item.title')}</span> {record.page.title}
                                                </li>}
                                                {record.field && <li><span
                                                    className={`${classes.param} color-secondary inline`}>{t('group.item.title')}</span> {record.field.title}
                                                </li>}
                                            </ul>
                                        </Collapse> : null}
                                    </div>)
                                },
                            },
                            {
                                dataIndex: 'created_at',
                                className: 'text-secondary padding-none-horizontal',
                                responsive: ['md'],
                                width: 150,
                                render: (title, record) => momentFromUnix(record.created_at, 'DD MMM YYYY HH:mm'),
                            },
                            {
                                key: 'actions',
                                className: 'align-right ' + classes.actions,
                                width: 65,
                                render: (text, record) => (
                                    <div className="table-row-buttons">
                                        {filters.is_deleted ?
                                            <Tooltip title={l('table.icon.restore')}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() => recordRestore(record.id, 'userUtm')}
                                                    icon={<ExperimentOutlined/>}
                                                />
                                            </Tooltip> :
                                            <Tooltip title={l('table.icon.delete')}>
                                                <Button
                                                    type="text"
                                                    size="large"
                                                    onClick={() => recordDelete(record.id, 'userUtm')}
                                                    icon={<DeleteOutlined/>}
                                                />
                                            </Tooltip>
                                        }
                                    </div>
                                ),
                            },
                        ]}
                        onFolderChange={onFolderChange}
                        folder={{
                            id: 'utm',
                            title: t('user.object.section.utm') + ': ' + utmItems.length,
                            type: 'folder.section', // for locale
                            opened: inArray('utm', folders),
                        }}
                        recordCreate={() => recordFieldEdit(0, 'utm')}
                    />

                    {/* Projects List */}
                    {isMainProject && <FolderListSimple
                        key={'profile-section-projects'}
                        admin={admin}
                        section={section}
                        sectionSaga={'UserProject'}
                        project={project_item}
                        filters={filters}
                        items={user.projects.map((record) => {
                            return {
                                key: record.id,
                                id: record.id,
                                title: record.title,
                                admin: deepGet(record, ['admin', 'user']),
                            }
                        })}
                        columns={[
                            {
                                dataIndex: 'title',
                                className: 'cursor-pointer',
                                onCell: (record) => {
                                    const projectLink = `${routes.project_list}/${record.id}`
                                    return {
                                        onClick: (ev) => navigate(projectLink),
                                        onAuxClick: (ev) => redirect(projectLink, '_blank'),
                                        onContextMenu: (ev) => (ev.preventDefault()),
                                    };
                                },
                            },
                            {
                                dataIndex: 'admin',
                                className: 'cursor-pointer',
                                responsive: ['sm'],
                                width: 200,
                                render: (value, record) => {
                                    return value && value.title ? <NavigateLink
                                        link={recordItem(value.id, 'user', 'profile', '', true)}
                                        className="color-secondary hover:text-underline"
                                    >
                                        {value.title}
                                    </NavigateLink> : null
                                },
                                // onCell: (record, rowIndex) => {
                                //     return {
                                //         onClick: (ev) => copyToClipboard(record.value),
                                //     };
                                // },
                            },
                        ]}
                        onFolderChange={onFolderChange}
                        folder={{
                            id: 'project',
                            title: t('user.object.section.project') + ': ' + user.projects.length,
                            type: 'folder.section', // for locale
                            opened: inArray('project', folders),
                        }}
                        // recordCreate={() => recordFieldEdit(0, 'page')}
                    />}

                    <div className="margin-top-sm">
                        <div className="float-right inline" style={{maxWidth: '40%'}}>
                            <Button type="text" icon={<ArrowLeftOutlined/>} onClick={() => {
                                backToList();
                            }}> {t('user.action.return')} </Button>

                            {item.id && connectedUserId === item.id ?
                                <Button
                                    type="text"
                                    icon={<CloseOutlined/>}
                                    onClick={disconnectUser}
                                >
                                    <span>
                                        <span className="hidden-xs"> {t('user.action.disconnect')}</span>
                                        <span className="visible-xs-inline"> {t('user.action.disconnect_short')}</span>
                                    </span>
                                </Button> : <Tooltip
                                    title={t('user.action.connect_desc')}
                                >
                                    <Button
                                        type="text"
                                        icon={<TeamOutlined/>}
                                        onClick={connectUserToAdmin}
                                    >
                                    <span>
                                        <span className="hidden-xs"> {t('user.action.connect')}</span>
                                        <span className="visible-xs-inline"> {t('user.action.connect_short')}</span>
                                    </span>
                                    </Button>
                                </Tooltip>}
                        </div>

                        {(item.deleted_at === 0) ? <Button
                            type="text"
                            danger={false}
                            icon={<DeleteOutlined/>}
                            onClick={() => {
                                recordDelete(id, section);
                            }}
                        >
                            {t('user.action.delete')}
                        </Button> : <Button
                            type="text"
                            danger={true}
                            icon={<ExperimentOutlined/>}
                            onClick={() => {
                                recordRestore(id, section);
                            }}
                        >
                            {t('user.action.restore')}
                        </Button>}

                        {(item.ignore) ?
                            <Button type="text" danger icon={<LikeOutlined/>} onClick={() => {
                                recordChangeStatus(id, item.ignore);
                            }}> {t('user.action.unban')} </Button> :

                            <Button type="text" icon={<UserDeleteOutlined/>} onClick={() => {
                                recordChangeStatus(id, item.ignore);
                            }}> {t('user.action.ban')} </Button>
                        }
                    </div>
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default UserProfile