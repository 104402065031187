import React, {useEffect, useState} from 'react'
import {dotenv} from "../../config/config";
import {
    Button,
    Input,
    Tooltip,
    Typography,
    InputNumber,
    Row,
    Col,
} from "antd";
import {PlusOutlined, ShoppingOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    createMarkup,
    deepGet,
    redirect,
    roundPrice
} from "../../library/functions";
import {useTranslation} from "react-i18next";
import {FormItemWrapper} from "../../components/Form/FormItemWrapper";
import {reachGoal} from "../../library/metrics";

const {Title, Text} = Typography;

export const AdminBalance = ({section, projectId, className, titleLevel = 3}) => {
    const {t} = useTranslation()

    // init hooks and states
    const dispatch = useDispatch()
    const [orderAmount, setOrderAmount] = useState('')
    const [projectAmount, setProjectAmount] = useState('')

    // sync with store
    const {admin, userField, project, order} = useSelector(store => store)
    const project_item = project.item
    const project_id = project_item.id ?? 0
    const proj_balance = project_item.balance ? parseFloat(project_item.balance) : 0

    let admin_phones = []
    if (admin.user && admin.user.contacts) admin_phones = admin.user.contacts.filter(e => e.type === 'phone')
    const admin_phone = admin_phones.length ? admin_phones[0].value : ''

    // get fields values
    const balance = deepGet(userField, 'balance.value', 0)
    const coins = deepGet(userField, 'coins.value', 0)

    useEffect(() => {
        if (admin.authorized && admin.token) {
            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: ['balance', 'coins'],
                }
            })
        }
        //eslint-disable-next-line
    }, [admin])

    useEffect(() => {
        if (order.url) redirect(order.url)
        // else if (order.url && !order.loading.balance) dispatch(setOrderUrl(''))
        //eslint-disable-next-line
    }, [order.url])

    useEffect(() => {
        if (projectId && projectId !== project_id) {
            dispatch({type: 'getProjectItem', admin, id: projectId});
        }
        // else if (!projectId && project_id) {
        //     dispatch(setProjectItem({}))
        // }
        //eslint-disable-next-line
    }, [projectId])

    // local locale func
    const l = (c) => t('account.balance.' + c)

    const onProjectFormSubmit = () => {
        // console.log('onProjectFormSubmit', projectAmount)

        // catch metrics goal
        reachGoal('projectBalance', {order_price: projectAmount})

        dispatch({
            type: 'increaseProjectBalance',
            admin,
            data: {
                project_id: project_item.id,
                amount: projectAmount,
            }
        })
    }

    const onOrderFormSubmit = () => {
        // console.log('onOrderFormSubmit', orderAmount)

        // catch metrics goal
        reachGoal('order', {order_price: orderAmount})

        dispatch({
            type: 'createAppOrder',
            admin,
            data: {
                // title: t('project.tariff.account.amount.product_title'),
                price: orderAmount,
                related_project_id: project_id,
                user_phone: admin_phone,
                to_project: true,
            }
        })
    }

    return (
        <div className={className}>
            {/*<Row gutter={24}>
                <Col xs={24} sm={24}>*/}
                    <Title level={3}>
                        {l('project.title')}
                    </Title>

                    <div className="ant-form-vertical">
                        <FormItemWrapper
                            className="margin-none-bottom"
                            // desc={<span dangerouslySetInnerHTML={createMarkup(l('project.desc'))}/>}
                        >
                            <div className="ant-form-item-label">
                                <label>
                                    {project_id && projectId ? <span className="margin-right-sm">
                                        {l('money')}: {roundPrice(proj_balance)} {t('common.money.symbol')}
                                    </span> : <Text type="danger">{l('project.undefined')}</Text>}
                                </label>
                            </div>

                            <Input.Group compact className="inline-important">
                                <InputNumber
                                    min={0}
                                    step={500}
                                    inputMode="decimal"
                                    placeholder={l('amount.placeholder')}
                                    onChange={setOrderAmount}
                                    // formatter={(value) => `${value} $({t('common.money.symbol')})`}
                                    style={{
                                        width: 200,
                                        maxWidth: 'calc(100% - 150px)',
                                    }}
                                />
                                <Button
                                    style={{height: 34}}
                                    // className="float-right"
                                    type="primary"
                                    loading={order.loading.balance}
                                    disabled={orderAmount < 100 || !project_id || !projectId}
                                    icon={<ShoppingOutlined/>}
                                    onClick={onOrderFormSubmit}
                                >{t('common.action.add_funds')}</Button>
                            </Input.Group>

                            {/*<Input.Group compact className="inline-important">
                                <InputNumber
                                    min={0}
                                    step={500}
                                    inputMode="decimal"
                                    placeholder={l('amount.placeholder')}
                                    onChange={setProjectAmount}
                                    // formatter={(value) => `${value} {t('common.money.symbol')}`}
                                    style={{
                                        width: 200,
                                        maxWidth: 'calc(100% - 150px)',
                                    }}
                                />
                                <Button
                                    // className="float-right"
                                    type="primary"
                                    loading={order.loading.project}
                                    icon={<PlusOutlined/>}
                                    disabled={projectAmount <= 0 || !project_id || !projectId}
                                    onClick={onProjectFormSubmit}
                                >{t('common.action.add_funds')}</Button>
                            </Input.Group>*/}
                        </FormItemWrapper>
                    </div>
                {/*</Col>*/}


                {/* TODO: return second col - optional */}
                {/*<Col xs={24} sm={12}>
                    <Title level={titleLevel}>
                        {l('title')}
                    </Title>

                    <div className="ant-form-vertical">
                        <div className="inline" style={{paddingRight: 0}}>
                            <Tooltip
                                title={orderAmount < 100 ? l('amount.required') : ''}
                                placement="topRight"
                            >
                                <FormItemWrapper className="margin-none-bottom" desc={l('amount.desc')}>
                                    <div className="ant-form-item-label"><label>
                                        <span
                                            className="margin-right-sm">{l('money')}: {roundPrice(balance)} {t('common.money.symbol')} </span>
                                        <span>{l('coins')}: {roundPrice(coins)}</span>
                                    </label></div>

                                    <Input.Group compact className="inline-important">
                                        <InputNumber
                                            min={0}
                                            step={500}
                                            inputMode="decimal"
                                            placeholder={l('amount.placeholder')}
                                            onChange={setOrderAmount}
                                            // formatter={(value) => `${value} $({t('common.money.symbol')})`}
                                            style={{
                                                width: 200,
                                                maxWidth: 'calc(100% - 150px)',
                                            }}
                                        />
                                        <Button
                                            style={{height: 34}}
                                            // className="float-right"
                                            type="primary"
                                            loading={order.loading.balance}
                                            disabled={orderAmount < 100}
                                            icon={<ShoppingOutlined/>}
                                            onClick={onOrderFormSubmit}
                                        >{t('common.action.add_funds')}</Button>
                                    </Input.Group>
                                </FormItemWrapper>
                            </Tooltip>
                        </div>
                    </div>
                </Col>*/}
            {/*</Row>*/}
        </div>
    )
}