import React, {useState} from 'react'
import {useNavigate} from "react-router-dom"
import {Button, Tooltip, Typography} from 'antd';
import {useDispatch} from "react-redux";
import {routes} from "../../../config/config";
import {l} from "../../../library/locale";
import {deepGet, getUtcDateTime, ucFirst} from "../../../library/functions";
import ListTableRowMenu from "../../../components/List/Table/ListTableRowMenu";
import {TableAvatar} from "../../../components/List/Table/TableAvatar";
import {
    AiOutlineCopy,
    AiOutlineEye,
    AiOutlineEyeInvisible,
    AiOutlineSetting
} from "react-icons/ai";
import FoldersList from "../../../components/List/Table/FoldersList";

const {Text} = Typography;

export const LayoutListTable = (
    {
        admin,
        project,
        section,
        adminSection,
        list,
        filters,
        recordCreate,
        categoriesMap,
        isAllowed = true,
    }
) => {
    const Section = ucFirst(section)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    // let list_items = createObjectFromObjectsArray(list);

    // navigate
    const recordEdit = (record) => {
        let url = `${routes.project_list}/${project.id}/${section}/edit/${record.id}`;
        if (filters.site_id) url += `?site_id=${filters.site_id}`;
        navigate(url);
    }
    const recordOpen = (record) => {
        if (!isAllowed) return;
        recordEdit(record)
    }
    // const recordOpen = (record) => {
    //     let url = `${routes.project_list}/${project.id}${routes.local.page_list}?layout_name=${record.name}`;
    //     if (filters.site_id) url += `&site_id=${filters.site_id}`;
    //     navigate(url);
    // }
    // saga
    const recordCopy = (id) => dispatch({type: 'copy' + Section, admin, id})
    const recordDelete = (id) => dispatch({type: 'delete' + Section, admin, id, project_id: project.id})
    const recordRestore = (id) => {
        const params = {id: id, project_id: project.id}
        dispatch({type: 'restore' + Section, admin, data: params})
    }

    const recordChangeStatus = (id, newStatus) => {
        const data = {
            id: id,
            project_id: project.id,
            is_on: newStatus,
        }
        dispatch({type: 'update' + Section, admin, data})
    }

    const columns = [
        {
            dataIndex: 'id',
            className: 'cursor-pointer avatar align-center',
            width: 90,
            responsive: ['sm'],
            render: (value, record) => {
                return <div title={value}>
                    <TableAvatar
                        icon={record.icon_name}
                        isOn={record.status}
                    />
                </div>
            },
            onCell: (record) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title',
            className: 'cursor-pointer',
            responsive: ['sm'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
        },
        {
            dataIndex: 'title_xs',
            className: 'cursor-pointer table-row-title title-link',
            responsive: ['xs'],
            onCell: (record, rowIndex) => {
                return {
                    onClick: (ev) => recordOpen(record)
                };
            },
            render: (text, record) => record.status ? <Text>{text}</Text> : <Text type="danger">{text}</Text>,
        },
        {
            dataIndex: 'site_title',
            responsive: ['sm'],
            // width: 150,
            render: (text, record) => <span className="text-secondary">
                {text}
            </span>,
        },
        {
            dataIndex: 'name',
            responsive: ['xl'],
            width: 150,
            render: (text, record) => <span className="text-secondary copy-on-click">
                {text}
            </span>,
        },
        // {
        //     dataIndex: 'status',
        //     className: 'cursor-pointer',
        //     responsive: ['sm'],
        //     width: 115,
        //     render: (status) => {
        //         return <Tooltip title={l('common.action.click_for_toggle')}>{status ?
        //             <Text>{l('common.status.short.on')}</Text> :
        //             <Text type="danger">{l('common.status.short.off')}</Text>}
        //         </Tooltip>
        //     },
        //     onCell: (record, rowIndex) => {
        //         return {
        //             onClick: (ev) => recordChangeStatus(record.id, !record.status)
        //         };
        //     },
        // },
    ];

    if (isAllowed) columns.push({
        key: 'actions',
        responsive: ['sm'],
        width: 105,
        render: (text, record) => (
            <div className="table-row-buttons">
                <Tooltip title={record.status ? l('table.icon.off') : l('table.icon.on')}>
                    <Button
                        type="text"
                        onClick={() => recordChangeStatus(record.id, !record.status)}>
                        {record.status ? <AiOutlineEye/> : <AiOutlineEyeInvisible/>}
                    </Button>
                </Tooltip>
                <Tooltip title={l('table.icon.duplicate')}>
                    <Button
                        type="text"
                        onClick={() => recordCopy(record.id)}>
                        <AiOutlineCopy/>
                    </Button>
                </Tooltip>
                <Tooltip title={l('table.menu.edit')}>
                    <Button type="text" onClick={() => {
                        recordEdit(record)
                    }}><AiOutlineSetting/></Button>
                </Tooltip>
            </div>
        )
    });

    columns.push({
        key: 'menu',
        className: 'menu-column',
        width: 55,
        render: (text, record) => (<ListTableRowMenu items={table_menu_items} record={record}/>),
    });

    const table_menu_items = [
        {
            key: 'tableMenuEdit',
            label: l('table.menu.edit'),
            action: (record) => recordEdit(record)
        },
        {
            key: 'tableMenuCopy',
            label: l('table.menu.duplicate'),
            action: (record) => recordCopy(record.id)
        },
        isAllowed ? {
            key: 'tableMenuStatus',
            label: l('table.menu.status'),
            action: (record) => recordChangeStatus(record.id, !record.status)
        } : null,
        {
            key: 'tableMenuDelete',
            label: l('table.menu.delete'),
            action: (record) => recordDelete(record.id)
        },
    ]

    // ==== Restore Button ====

    if (filters.is_deleted) {
        // change delete to restore
        table_menu_items.pop()
        table_menu_items.push({
            key: 'tableMenuRestore',
            label: l('table.menu.restore'),
            action: (record) => recordRestore(record.id)
        })
    }

    // ==== Rows Data ====

    let data = [];
    for (let i = 0; i < list.length; i++) {
        const now = getUtcDateTime();
        const record = list[i]
        data.push({
            id: record.id,
            type: record.type,
            name: record.name,
            site_title: deepGet(categoriesMap, [String(record.site_id)], '—'),
            key: record.id + '-listRow',
            icon_name: record.icon_name,
            title: record.title,
            title_xs: record.title,
            folder: record.params && record.params.folder_id || 0,
            status: record.is_on,
            deleted: record.deleted_at > 0,
        });
    }

    // ==== Render ====
    return <FoldersList
        section={section}
        admin={admin}
        project={project}
        filters={filters}
        items={data}
        columns={columns}
        recordCreate={recordCreate}
    />
}

