import React, {useRef, useState} from "react";
import {Button, Modal} from "antd";
import {PlayCircleFilled} from "@ant-design/icons";
import classes from "./VideoSimple.module.scss"

export const VideoSimple = ({videoRef, url = '', onEnded = null, controls = true, buttonStyle = 'light'}) => {
    const [paused, setPaused] = useState(true)

    const playPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setPaused(false)
        } else {
            videoRef.current.pause();
            setPaused(true)
        }
    }

    return (<div className={classes.player_wrapper}>
        <video className="full-width" ref={videoRef} onEnded={onEnded} controls={controls} onClick={playPause}>
            <source src={url} type="video/mp4"/>
            Sorry. Your browser does not support the video tag
        </video>

        {buttonStyle ? <Button type="text" onClick={playPause} className={classes.play_pause_button}>
            {paused ? <PlayCircleFilled
                className={`${classes.play_pause_icon}${buttonStyle === 'dark' ? ' ' + classes.icon_dark : ''}`}
            /> : null}
        </Button> : null}
    </div>)
}


export const VideoModal = (
    {
        url,
        title = '',
        onOk = null,
        onCancel = null,
        isVisibleDefault = false,
        controls = true,
        buttonStyle = 'light'
    }
) => {
    const videoRef = useRef()
    const [isVisible, setVisible] = useState(isVisibleDefault)

    const stopAndHide = () => {
        if (videoRef.current) videoRef.current.pause();
        setVisible(false);
    };

    const handleOk = () => {
        stopAndHide();
        if (onOk) onOk()
    };

    const handleCancel = () => {
        stopAndHide();
        if (onCancel) onCancel()
    };

    return <Modal
        width={1280}
        centered={true}
        className={classes.modal_wrapper}
        title={title}
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
    >
        <VideoSimple videoRef={videoRef} url={url} buttonStyle={buttonStyle} onEnded={handleOk} controls={controls}/>
    </Modal>
}


