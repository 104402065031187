import React, {useEffect, useState} from 'react'
import {Layout} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {dotenv, routes} from "../../config/config";
import AppWrapper from "../Layouts/AppWrapper/AppWrapper";
import {Banner} from "../../components/Banner/Banner";
import {l} from "../../library/locale";
import {ContainerOutlined} from "@ant-design/icons";
import {Content} from "antd/es/layout/layout";
import {CollapseAccordion} from "../../components/Collapse/CollapseAccordion";
import CardAccount, {ServiceDescription} from "../../components/Card/CardAccout";
import {setUserField} from "../../redux/reducers/UserFieldReducer";

const user_fields = [
    'status','bio','about', // profile
    'balance', 'coins', // tariffs
    'all_sections','firstshopping','millionmessages', // achievements
]

const AdminItem = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const goToEdit = (e) => {
        e.preventDefault()
        navigate(routes.admin.settings);
    }

    // sync with store
    const {admin, userField, order, discount} = useSelector(store => store)
    const item = admin.user
    window.store = admin;

    // let achievements = []
    // for (const [key, field] of Object.entries(userField)) {
    //     if (field.system_field_type === 'achievement') achievements.push(field)
    // }
    // achievements = orderObjectsByFieldValues(achievements, 'id')

    const collapse_account_items = [
         {
            title: l('account.collapse.description_services'),   // Описание услуг
            collapseIcon: <ContainerOutlined/>,
            content: <ServiceDescription about={userField.about?.value || ''}/>
        },
        // {
        //     title: l('account.collapse.achievements'),           // Достижения
        //     collapseIcon: AiOutlineTrophy(),
        //     content: <AchievementsCards achievements={achievements}/>
        // },
        // {
        //     title: l('account.collapse.certificates'),           // Сертификаты
        //     collapseIcon: AiOutlineStar(),
        //     content: " "
        // },
        // {
        //     title: l('account.collapse.cases'),                 // Кейсы
        //     collapseIcon: AiOutlineInbox(),
        //     content: " "
        // },
        // {
        //     title: l('account.collapse.templates'),             // Шаблоны
        //     collapseIcon: AiOutlineShop(),
        //     content: " "
        // }
    ]

    // get data from API
    useEffect(() => {
        // avoid non authorized run
        if (admin.authorized) {
            dispatch({type: 'getAdminItem', admin}); // get data once

            dispatch({
                type: 'getUserFieldSet',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    user_id: admin.user.id,
                    field_names: user_fields,
                }
            })

            dispatch({
                type: 'getDiscountList',
                admin,
                filters: {
                    project_id: dotenv.main_project,
                    product_id: dotenv.main_product,
                    partner_user_ids: [admin.user.id],
                    page_size: 1
                }
            })
        }

    }, [admin.authorized])

    const changeStatus = () => {
        // init field post params
        const newUserFieldData = {
            field_name: 'status',
            value: !userField.status.value
        }

        // save to store by Reducer
        dispatch(setUserField(newUserFieldData))

        // save to backend by Saga
        dispatch({
            type: 'storeUserField',
            admin,
            data: {
                project_id: dotenv.main_project,
                user_id: item.id,
                ...newUserFieldData
            }
        })
    }


//     useEffect(() => {
//         if (!admin.authorized) return navigate(routes.account)        // юзер нажал CTRL+F5 или F5 - уходим по адресу этой же страницы. роутинг сам проверит, авторизован пользователь или нет. и отправит его снова сюда или на авторизацию
// //eslint-disable-next-line
//     }, [admin])

    return (
        <AppWrapper className={"bg-gray-light"}>
            <Layout className="site-layout site-layout-background account-item">
                <Banner image="ship"/>
                <Content className="page-container site-layout-background">

                    <CardAccount
                        admin={admin}
                        order={order}
                        discount={discount}
                        userField={userField}
                        status={userField.status?.value}
                        bio={userField.bio?.value || ''}
                        balance={userField.balance?.value || 0}
                        coins={userField.coins?.value || 0}
                        goToEdit={goToEdit}
                        changeStatus={changeStatus}
                    />

                    {/*<CardProgress/>*/}

                    <CollapseAccordion items={collapse_account_items}/>
                </Content>
            </Layout>
        </AppWrapper>
    )
}

export default AdminItem